import React, { useRef } from "react";
import styles from "./GoogleMap.module.scss";
import useOnClickOutside from "../../hooks/useOutsideClick";

const GoogleMap = ({ isActive, setIsActive, coordinates }) => {
    const wrapperRef = useRef();

    useOnClickOutside(wrapperRef, () => {
        if (isActive) {
            setIsActive(false);
        }
    });

    return isActive ? (
        <div className={styles["google_map__container"]}>
            <button className={styles["button"]}></button>
            {/* <img
        className={styles["map"]}
        ref={wrapperRef}
        src={map}
        alt="Google map"
      ></img> */}
            <iframe
                ref={wrapperRef}
                width="90%"
                height="90%"
                frameBorder="0"
                title="map"
                marginHeight="0"
                marginWidth="0"
                src={`https://maps.google.com/maps?q=${coordinates.lat},${coordinates.long}&z=16&output=embed`}
            ></iframe>
        </div>
    ) : null;
};

export default GoogleMap;
