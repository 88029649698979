import React, { useState, useRef, useEffect } from "react";
import getRatesService from "../../../../services/getRateService";
import styles from "./index.module.scss";
import useOnClickOutside from "../../../../hooks/useOutsideClick";
import Abutton from "../../../common/AButton";
import openDownloadPage from "../../../../helpers/OpenDownloadLink";
import MapField from "../mapField";
import Loading from "../../../common/Loading";

const moneyName = {
    "honk kong": "HKD",
    philippines: "PHP",
    israel: "ILS",
    thailand: "THB",
    indonesia: "IDR",
    vietnam: "VND",
    nepal: "NPR",
    china: "RMB",
    india: "INR",
    "sri lanka": "LKR",
};

const TITLE_NAMES = ["Simple", "Cheaper", "Fast", "Reliable", "Secure"];

const PaymentField = ({ countries, country, goBack }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenCurrency, setIsOpenCurrency] = useState(false);
    const [isInputActive, setIsInputActive] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(country);
    const [amount, setAmount] = useState(0);
    const [countryCurrency, setCountryCurrency] = useState(
        () => moneyName[country.toLowerCase()]
    );

    const maxHkdToSend = 4000; //hardcoded data

    const [exchangeRate, setExchangeRate] = useState();

    const [money, setMoney] = useState("");
    const HKD = "HKD";
    const [showMaps, setShowMaps] = useState(false);

    const componentRef = useRef();
    const currencyRef = useRef();

    useOnClickOutside(componentRef, () => setIsOpen(false));
    useOnClickOutside(currencyRef, () => setIsOpenCurrency(false));

    useEffect(() => {
        if (isInputActive === true) {
            setIsLoading(true);
            setTimeout(() => setIsInputActive(false), 1000);
        }
        if (isInputActive === false) {
            updateAmount(money);
            setIsLoading(false);
        }
    }, [isInputActive]);

    const getRates = async () => {
        try {
            if(amount) {
                setIsLoading(true);
            }
            
            const result = await getRatesService({
                //calculates  (fee + 1) => 1 hkd to current chosen country currency
                country: selectedCountry,
                amount: fee + 1,
            });
            setExchangeRate(parseFloat(result.calculatedAmount).toFixed(2));
        } catch (exception) {
            console.log(exception);
        }
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setCountryCurrency(() => moneyName[selectedCountry.toLowerCase()]);
        updateAmount(money);
        getRates();
        // setTimeout(async () => {
        //     updateAmount(money);
        // }, 1000);
    }, [selectedCountry]);

    useEffect(() => {
        if (isInputActive === false) {
            getRates();
        }
    }, [money]);

    let fee = 22;

    const updateAmount = async (money) => {
        try {
            const result = await getRatesService({
                country: selectedCountry,
                amount: money,
            });
            setAmount(result.calculatedAmount);
            setIsLoading(false);
        } catch (err) {
            setAmount(0);
        }
    };

    const onInputHandler = (ev) => {
        const inputValue = ev.target.value;
        if (
            (Number(inputValue) || inputValue === "") &&
            Number(inputValue) <= maxHkdToSend
        ) {
            setIsInputActive(true);
            setMoney(ev.target.value);
        } else if (ev.target.value.length === 1) {
            setAmount("");
        }
    };

    return (
        <section>
            <div
                className={styles["back__button"]}
                onClick={() => goBack(true)}
            >
                {"Back"}
            </div>
            <div className={styles["blue-label-items"]}>
                {TITLE_NAMES.map((value, index) => (
                    <React.Fragment key={index}>
                        <p className={`${styles["blue-label-item"]}`}>
                            {value}
                        </p>
                        {index < TITLE_NAMES.length - 1 ? (
                            <div className={styles["blue-dot"]}></div>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                ))}
            </div>
            {!showMaps ? (
                <div className={styles["payment_field_container"]}>
                    <div className={styles["context_field"]}>
                        <div
                            className={`${styles["field_box"]} ${
                                isOpen ? styles["active"] : ""
                            }`}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <label className={styles["field_box_label"]}>
                                Destination Country
                            </label>
                            <span
                                className={"selectbox__title"}
                                ref={componentRef}
                            >
                                {selectedCountry}
                            </span>
                            {isOpen && (
                                <ul
                                    className={`selectbox__items ${styles["outline--gray"]}`}
                                >
                                    {countries.map((country, index) => (
                                        <li
                                            key={`${country}_${index}`}
                                            className="selectbox__item"
                                            onClick={(e) =>
                                                setSelectedCountry(country)
                                            }
                                        >
                                            {country}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div
                            className={`${styles["field_box"]} ${styles["flex"]}`}
                        >
                            <label className={styles["field_box_label"]}>
                                You Send
                            </label>
                            <div
                                className={`${styles["input_field"]} ${styles["flex"]}`}
                            >
                                <input
                                    type="text"
                                    value={money}
                                    onChange={(e) => onInputHandler(e)}
                                ></input>
                                <div
                                    className={`${
                                        styles["selectbox_currency"]
                                    } ${
                                        isOpenCurrency ? styles["active"] : ""
                                    }`}
                                    onClick={() =>
                                        setIsOpenCurrency(!isOpenCurrency)
                                    }
                                    ref={currencyRef}
                                >
                                    <span
                                        className={
                                            styles["selectbox__currency_title"]
                                        }
                                    >
                                        {HKD}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles["field_box"]} ${styles["flex"]}`}
                        >
                            <label className={styles["field_box_label"]}>
                                Reciever Gets
                            </label>
                            <div
                                className={`${styles["input_field"]} ${styles["flex"]}`}
                            >
                                {isLoading ? (
                                    <Loading
                                        classNames={styles.loading}
                                    ></Loading>
                                ) : (
                                    <p>{money > fee ? amount : "Amount must be higher than Fee"}</p>
                                )}

                                <p>{countryCurrency}</p>
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <p>
                                1 {HKD} = {exchangeRate} {countryCurrency}
                            </p>
                            <p>
                                Fee {fee} {HKD}
                            </p>
                        </div>
                        <b
                            className={styles["download_link"]}
                            onClick={() => openDownloadPage()}
                        >
                            Download our Mobile App
                        </b>
                        <Abutton size="auto" onClick={() => setShowMaps(true)}>
                            Find a nearby branch
                        </Abutton>
                    </div>
                </div>
            ) : (
                <MapField></MapField>
            )}
        </section>
    );
};

export default PaymentField;
