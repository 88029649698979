import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import ContentBox from "../../common/ContentBox";
import { ViewImage, ViewRightLeft } from "../../View";
import { Text } from "../../typography";
import complianceBanner from "../../../assets/media/compilanceAndReiskMore_banner.svg";

const ComplianceAndRiskMore = () => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);

    return (
        <>
            {!opened ? (
                <button
                    className="contentBox__learnMoreBtn btn-hover"
                    onClick={() => setOpened(true)}
                >
                    {t("common.learnMoreBtn")}
                </button>
            ) : (
                <>
                    <div className="principlesBox">
                        <div className="principlesBox__container">
                            <Text text={t("complianceAndRisk.titlePrinciples")} bold={true} size="xl" color="white"></Text>
                            <ul className="principlesBox__list d-flex">
                                <li className="principlesBox__list__item d-flex">
                                    <p>{t("complianceAndRisk.p1")}</p>
                                </li>
                                <li className="principlesBox__list__item d-flex">
                                    <p>{t("complianceAndRisk.p2")}</p>
                                </li>
                                <li className="principlesBox__list__item d-flex">
                                    <p>{t("complianceAndRisk.p3")}</p>
                                </li>
                                <li className="principlesBox__list__item d-flex">
                                    <p>{t("complianceAndRisk.p4")}</p>
                                </li>
                                <li className="principlesBox__list__item d-flex">
                                    <p>{t("complianceAndRisk.p5")}</p>
                                </li>
                                <li className="principlesBox__list__item d-flex">
                                    <p>{t("complianceAndRisk.p6")}</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <ViewRightLeft>
                        <ContentBox
                            title={t("complianceAndRisk.titleMore")}
                            description={t(
                                "complianceAndRisk.descriptionMore",
                                { returnObjects: true }
                            )}
                        />
                        <ViewImage image={complianceBanner}></ViewImage>
                    </ViewRightLeft>
                </>
            )}
        </>
    );
};

export default ComplianceAndRiskMore;
