import { useTranslation } from "react-i18next";
import React from "react";
import InputCard from "../../common/InputCard/InputCard";
import EmailInput from "../../common/EmailInput";
import { ViewRightLeft, ViewImage } from "../../View";
import Banner from "../../../assets/media/network_banner.svg";

import ContentBox from "../../common/ContentBox";

const Network = () => {
    const { t } = useTranslation();

    // const data = [
    //     {
    //         subTitle: "Broad payment method acceptance",
    //         description:
    //             "Let international customers pay with their preferred payment methods like cards and bank transfers. All transactions settle in USD directly into your ATS Account — where you can convert to a connected bank account or leverage the power of ATS Wallet Account.",
    //     },
    // ];

    return (
        <>
            <ViewRightLeft>
                <ContentBox
                    title={t("network.title")}
                    description={t("network.description", {
                        returnObjects: true,
                    })}
                />
                <ViewImage image={Banner}></ViewImage>
            </ViewRightLeft>
            <div>
                <InputCard
                    title={"want to"}
                    titleGreen={t("network.emailGreenTxt")}
                    subtitle={"join us, and leave the rest to us"}
                    type={"blank"}
                >
                    <EmailInput
                        placeholder={t("common.emailPlc")}
                        buttonName={t("common.join")}
                        icon={true}
                    />
                </InputCard>
            </div>
        </>
    );
};

export default Network;
