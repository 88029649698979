export const emailValidation = (mail) => {
  const mailValidationExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return mailValidationExp.test(mail);
}


export const isEmptyValidation = (value) => {
  const result = value.trim().length > 0;
  return  result
}

