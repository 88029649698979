import React from "react";

import styles from "./index.module.scss";
import Accordion from "../../../common/Accordion";
import AddressField from "./addressField";
import { Text } from "../../../typography";
import ADDRESSES from "../../../../constants/addresses";

const MapField = () => {
    return (
        <div className={styles["map_field_container"]}>
            {ADDRESSES.map((value) => {
                return (
                    <Accordion
                        data={value}
                        classNames={"address"}
                        key={value.id}
                    >
                        {value.data.map((value) => (
                            <AddressField
                                data={value}
                                key={value.id}
                            ></AddressField>
                        ))}
                    </Accordion>
                );
            })}
            <Text text="And at any Circle K shop" color="blue" bold={true} classNames={styles["text"]}></Text>
        </div>
    );
};

export default MapField;
