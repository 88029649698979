import React from "react";
import AButton from "../../common/AButton";
import checkmarkBlue from "../../../assets/media/icons/checkmark_blue.svg";
import checkmarkAqua from "../../../assets/media/icons/checkmark_aqua.svg";
import styles from "./index.module.scss";
import { ViewImage, ViewRightLeft } from "../../View";
import { Text } from "../../typography";

const SliderInfo = ({
    header,
    topInfo,
    bottomInfo,
    btn,
    offersList,
    offersListTitle,
    img,
}) => {
    // according to btn prop we draw button and decide the color of checkmark
    return (
      
            <ViewRightLeft>
                <div className={styles.leftSide}>
                    <Text size="xl" color="blue" text={header} bold={true}>{header}</Text>
                    <h4 className={styles.topInfo}>{topInfo}</h4>
                    {offersList && (
                        <div className={styles.offersList}>
                            <p>{offersListTitle}</p>
                            {offersList.map((item, idx) => (
                                <div
                                    key={"offList" + idx}
                                    className={styles.item}
                                >
                                    <img
                                        alt="checkmark"
                                        src={
                                            btn ? checkmarkAqua : checkmarkBlue
                                        }
                                    />

                                    <p>{item}</p>
                                </div>
                            ))}
                            {bottomInfo && (
                                <p className={styles.bottomInfo}>
                                    {bottomInfo}
                                </p>
                            )}
                        </div>
                    )}

                    {btn && (
                        <AButton
                            style={{ marginTop: 25 }}
                            variant="secondary--solid"
                            size="lg"
                            onClick={() => btn.function()}
                        >
                            {btn.text}
                        </AButton>
                    )}
                </div>
                <ViewImage image={img}></ViewImage>
            </ViewRightLeft>
      
    );
};

export default SliderInfo;
