import { Link } from "react-router-dom";
import React from "react";

const FooterList = ({ data }) => {
    return (
        <div className="footer__container__col">
            <span className="footer__container__col__title">{data.name}</span>
            {
                <ul className="footer__navigation">
                    {data.subCategories.map(item => <li key={item.id}>
                        
                        {<Link to={item.link}  className={`footer__navigation__item ${item.disabled? 'disabled' : ''}`} disabled={true}>{item.name}</Link>}
                    </li>)}
                </ul>
            }
        </div>
    )
};

export default FooterList;