import { useTranslation } from "react-i18next";
import React from "react";
import { ViewRightLeft, ViewImage } from "../../View";

import "./AboutUs.css";

import ContentBox from "../../common/ContentBox";
import MissionList from "./MissionList";
import aboutUsBanner from "../../../assets/media/aboutUs_banner.svg"

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <>
            <ViewRightLeft>
                <ContentBox
                    title={t("aboutUs.title")}
                    // subtitle={t("aboutUs.subTitle")}
                    description={t("aboutUs.description", {returnObjects: true})}
                />
                <ViewImage image={aboutUsBanner}></ViewImage>
            </ViewRightLeft>
            <ViewRightLeft reversed={true}>
                <ViewImage image="../images/whyChoose_banner.webp" backgroundBox={true}></ViewImage>
                <ContentBox
                    title={t("aboutUs.titleWhyChoose")}
                    description={t("aboutUs.descriptionWhyChoose")}
                />
            </ViewRightLeft>
            <div className="about-us__list-container">
                <h2 className="title-big blue">{t("aboutUs.titleMissions")}</h2>
                <MissionList />
            </div>
        </>
    );
};

export default AboutUs;
