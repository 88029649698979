import React from "react";
import PropTypes from "prop-types";
import styles from "./ViewImage.module.scss";

const ViewImage = ({ backgroundBox, image }) => {
    const classNames = `${backgroundBox ? styles["box"] : ""}`;

    return (
        <div className={styles["container"]}>
            <div className={classNames}></div>
            <img className={styles["image"]} src={image} alt="Banner"></img>
        </div>
    );
};

ViewImage.propTypes = {
    backgroundBox: PropTypes.bool,
    image: PropTypes.string,
};

ViewImage.defaultProps = {
    backgroundBox: false,
    image: "",
};

export default ViewImage;
