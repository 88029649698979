import url from "./url";
const contactUsService = async (data) => {
  const result = await fetch(
    url + "contactUs",
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );

  return result.json();
};

export default contactUsService;
