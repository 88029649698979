import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useOutsideClick";

import "../../assets/styles/selectbox.css";

const Selectbox = ({ data, onSelect, selected }) => {
    const [isOpen, setIsOpen] = useState();
    const { t } = useTranslation();

    const componentRef = useRef();
    useOnClickOutside(componentRef,()=>setIsOpen(false))


    const found = data.find((x) => x.id === selected);
    return (
        <div className={`selectbox ${isOpen ? "selectbox_active" : ""}`}>
            <span
                className="selectbox__title"
                ref={componentRef}
                onClick={() => setIsOpen(!isOpen)}
            >
                {found ? found.title : t("common.selectPlc")}
            </span>
            {isOpen && (
                <ul className="selectbox__items outline--gray">
                    {data.map((item,index) => (
                        <li
                            key={item.id}
                            className="selectbox__item"
                            onClick={() => {
                                onSelect(item.id);
                            }}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Selectbox;
