import React from "react";

import "../../assets/styles/contentBox.css";

import LearnMore from "./LearnMore";

const ContentBox = ({ title, subtitle, description, data }) => {
    return (
        <div className="contentBox">
            <h1 className="title-big blue">{title}</h1>
            <div className="contentBox__row">
                <p className="contentBox__subtitle">{subtitle}</p>
                {typeof description === "object" ? (
                    description.map((val,index) => (
                        <p className="contentBox__description" key={Math.random() * index}>{val}</p>
                    ))
                ) : (
                    <p className="contentBox__description">{description}</p>
                )}
            </div>
            {data && <LearnMore data={data} />}
        </div>
    );
};

export default ContentBox;
