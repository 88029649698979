import url from "./url";
const joinUsService = async (data) => {
  const result = await fetch(
    url + "joinUs",
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  return result.json();
};

export default joinUsService;
