import { Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";

import Payments from "../payments/Payments";
import Solutions from "./Solutions";

const RoutingSolutions = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/payments`} component={Payments} />
            <Route path={`${path}/payout`} component={Payments} />
            <Route path={`${path}/marketplace`} component={Payments} />
            <Route path="/" component={Solutions} />
        </Switch>
    );
};

export default RoutingSolutions;
