import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AButton from "../common/AButton";
import Dropdown from "./Dropdown";
import { categories } from "../../constants/menu";

import "../../assets/styles/navigation.css";

const Navigation = ({ history, mobile, onSetIsOpen }) => {
    const { t } = useTranslation();
    // const [lang, setLang] = useState(availableLanguages[0]); will be used if translation needed
    const [openedTab, setOpenedTab] = useState("");
    const [isOpened, setIsOpened] = useState(false);
    const onOpenHandler = (event, categoryName) => {
        setOpenedTab(categoryName);
        setIsOpened((prev) => {
            if (prev && openedTab === categoryName) {
                return false;
            }
            return true;
        });
    };

    const onCloseHandler = () => {
        setIsOpened(false);
        setOpenedTab("");
        onSetIsOpen(false);
    };

    // deactivated untill translations are done

    // const onLanguageChangeHandler = (lang) => {
    //     setLang({ ...lang });
    //     i18n.changeLanguage(lang.name);
    // };

    const componentRef = useRef();
    const { location } = history;

    const home = location.pathname === "/";
    const business = location.pathname.match(/^\/business/);

    const individuals = location.pathname.match(/^\/individuals/);
    const company = location.pathname.match(/^\/company/);

    let menuItems;
    if (mobile) {
        menuItems = categories;
    } else {
        if (home) {
            menuItems = categories.filter(
                (x) =>
                    x.name.toLowerCase() !== "business" &&
                    x.name.toLowerCase() !== "individuals"
            );
        } else if (business) {
            menuItems = categories.filter(
                (x) => x.name.toLowerCase() !== "individuals"
            );
        } else if (individuals) {
            menuItems = categories.filter(
                (x) => x.name.toLowerCase() !== "business"
            );
        } else if (company) {
            menuItems = categories.filter((x) => {
                return x.name.toLowerCase() !== "";
            });
        } else {
            menuItems = categories;
        }
    }

    let buttonsToShow;
    if (menuItems[0].name.toLowerCase() === "individuals") {
        buttonsToShow = (
            <AButton
                variant="primary--outline"
                size="sm"
                onClick={() => history.push("/business")}
            >
                {t("home.businessBtn")}
            </AButton>
        );

        if (location.pathname.toLowerCase().includes("company")) {
            buttonsToShow = null;
        }
    } else {
        buttonsToShow = (
            <AButton
                variant="primary--outline"
                size="sm"
                onClick={() => history.push("/individuals")}
            >
                {t("home.individualBtn")}
            </AButton>
        );
    }

    // menuItems = menuItems.map((x) => ({
    //     ...x,
    //     isActive: !!x.subCategories.find((y) => {
    //         return (
    //             y.link === location.pathname ||
    //             y.categories?.find((z) => z.link === location.pathname)
    //         );
    //     }),
    //     subCategories: x.subCategories.map((y) => ({
    //         ...y,
    //         ...((y.link === location.pathname ||
    //             y.categories?.find((z) => z.link === location.pathname)) && {
    //             isActive: true,
    //         }),
    //         ...(y.categories && {
    //             categories: y.categories.map((z) => ({
    //                 ...z,
    //                 ...(z.link === location.pathname && { isActive: true }),
    //             })),
    //         }),
    //     })),
    // }));

    return (
        <ul
            className={`${"navigation d-flex"} ${mobile ? "mobile" : ""}`}
            ref={componentRef}
        >
            {menuItems.length &&
                menuItems.map((cat, index) => {
                    let activeRout;
                    mobile
                        ? (activeRout = "")
                        : (activeRout = location.pathname.includes(
                              cat.name.toLowerCase()
                          )
                              ? "active__route"
                              : "");
                    return (
                        <div
                            key={`${cat.name}_${index}`}
                            className={`d-flex align-center navigation__item ${
                                cat.name === openedTab && isOpened
                                    ? `active ${activeRout}`
                                    : ` ${activeRout}`
                            }`}
                        >
                            <span
                                onClick={(ev) => {
                                    return onOpenHandler(ev, cat.name);
                                }}
                            >
                                {cat.name}
                            </span>
                            {openedTab === cat.name && isOpened && (
                                <Dropdown
                                    data={cat.subCategories}
                                    mobile={mobile}
                                    onClose={(e) => onCloseHandler(e)}
                                    onClick={onCloseHandler}
                                    componentRef={componentRef}
                                />
                            )}
                        </div>
                    );
                })}
            {mobile === false && menuItems?.length > 1 && (
                <li className="navigation__item d-flex">{buttonsToShow}</li>
            )}
        </ul>
    );
};
export default withRouter(Navigation);
