import { BrowserRouter as Router } from "react-router-dom";
import React, { useState } from "react";
import PdfView from "./components/common/PdfView";

import "./App.css";

import Routing from "./components/common/Routing";
import Header from "./components/Header";
import Footer from "./components/common/Footer";
import MessengerCustomerChat from "react-messenger-customer-chat";
import GoogleMap from "./components/googleMap/GoogleMap";

export const MapContext = React.createContext();

const App = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMapActive, setIsMapActive] = useState(false);
    const [coordinates, setCoordinates] = useState({
        lat: "22.373289467135915",
        long: "114.11636013011443",
    });

    const [pdfName, setPdfName] = useState("");

    return (
        <Router>
            <MessengerCustomerChat
                pageId="1843530052590517"
                appId="283229403821171"
            />

            <PdfView
                isOpen={isPopupOpen}
                setIsOpen={setIsPopupOpen}
                pdfName={pdfName}
            ></PdfView>
            <GoogleMap
                isActive={isMapActive}
                coordinates={coordinates}
                setIsActive={setIsMapActive}
            ></GoogleMap>
            <div className="App">
                <Header />
                <div className="content">
                    <MapContext.Provider
                        value={{
                            mapCoordinates: coordinates,
                            openMap: setIsMapActive,
                            changeMapCoordinates: setCoordinates,
                        }}
                    >
                        <Routing />
                    </MapContext.Provider>
                </div>
            </div>
            <Footer
                setPopupOpen={setIsPopupOpen}
                onChangePdfName={setPdfName}
            />
        </Router>
    );
};

export default App;
