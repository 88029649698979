import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navigation from "../common/Navigation";
import AButtonSandwich from "../common/AButtonSandwich";
import styles from "./index.module.scss";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", () => handleResize());
        return window.removeEventListener("resize", () => handleResize());
    }, []);

    useEffect(() => {
        if (width > 1000) {
            setIsOpen(false);
        }
    }, [width]);

    return (
        <div className={styles.header}>
            <Link to="/">
                <img src="/Logo.svg" alt="ATS" />
            </Link>
            {width > 1000 ? (
                <Navigation mobile={false} onSetIsOpen={setIsOpen} />
            ) : (
                <AButtonSandwich
                    isOpen={isOpen}
                    onClick={setIsOpen}
                ></AButtonSandwich>
            )}
            {isOpen ? (
                <Navigation mobile={true} onSetIsOpen={setIsOpen}></Navigation>
            ) : (
                ""
            )}
        </div>
    );
};

export default Header;
