import { Route, Switch } from "react-router-dom";
import React from "react";

import ComplianceAndRisk from "../company/complianceAndRisk/ComplianceAndRisk";
import RoutingSolutions from "../business/solutions/RoutingSolutions";
import LocalPayment from "../business/localPayment/LocalPayment";
import ContactUs from "../company/contactUs/ContactUs";
import Network from "../business/network/Network";
import Business from "../business";
import Wizdraw from "../personal/wizdraw/Wizdraw";
import AboutUs from "../company/aboutUs/AboutUs";
import Careers from "../company/careers/Careers";
import SendTo from "../personal/sendTo/SendTo";
import Individuals from "../personal";
import Faq from "../company/faq/Faq";
import Home from "../home/Home";
import ErrorPage from "../errorPage";

const Routing = () => {
    return (
        <Switch>
            {/* Business */}
            <Route path="/" exact component={Home} />
            <Route path="/business" exact component={Business} />
            <Route
                path="/business/our-solutions"
                component={RoutingSolutions}
                exact
            />
            <Route
                path="/business/local-payment"
                exact
                component={LocalPayment}
            />
            <Route path="/business/network" exact component={Network} />

            {/* Personal */}
            <Route path="/individuals" exact component={Individuals} />
            <Route path="/individuals/send-to" exact component={SendTo} />
            <Route path="/individuals/wizdraw" exact component={Wizdraw} />

            {/* Company */}
            <Route
                path="/company/compliance-risk"
                exact
                component={ComplianceAndRisk}
            />
            <Route path="/company/contact-us" exact component={ContactUs} />
            <Route path="/company/about-us" exact component={AboutUs} />
            <Route path="/company/careers" exact component={Careers} />
            <Route path="/company/faq" exact component={Faq} />

            {/* Default */}
            <Route path={"*"}>
                <ErrorPage />
            </Route>
        </Switch>
    );
};

export default Routing;
