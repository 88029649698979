import { useTranslation } from "react-i18next";
import React from "react";
import AButton from "../common/AButton";
import { ViewRightLeft } from "../View";
import "./Home.css";
import HomeBanner from "../../assets/media/home_banner.mp4";
import { useHistory } from "react-router";
import ContentBox from "../common/ContentBox";

const Home = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <>
            <div className="home">
                <ViewRightLeft>
                    <div className="home__left">
                        <ContentBox
                            title={t("home.title")}
                            description={t("home.description", {
                                returnObjects: true,
                            })}
                        ></ContentBox>
                        {/* {t("home.description", { returnObjects: true }).map(
                            (item, index) => (
                                <p key={Math.random() * index}>{item}</p>
                            )
                        )} */}
                        <div className="buttons-pair">
                            <AButton
                                style={{ marginRight: 10 }}
                                variant="primary--outline"
                                size="md"
                                onClick={() => history.push("/business")}
                            >
                                {t("home.businessBtn")}
                            </AButton>
                            <AButton
                                style={{ marginLeft: 10 }}
                                variant="primary--outline"
                                size="md"
                                onClick={() => history.push("/individuals")}
                            >
                                {t("home.individualBtn")}
                            </AButton>
                        </div>
                    </div>
                    <div className="home__right">
                        <video autoPlay playsInline loop muted>
                            <source src={HomeBanner}></source>
                        </video>
                    </div>
                </ViewRightLeft>
            </div>
        </>
    );
};

export default Home;
