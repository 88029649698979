import { useTranslation } from "react-i18next";
import React  from "react";

import "../../assets/styles/footer.css";

import { contactMail, contactNumber, phoneNumber } from "../../constants/company";
import { categories } from "../../constants/menu";
import FooterList from "./FooterList";

import {
    COOKIES_POLICY,
    PRIVACY_POLICY,
    TERMS_CONDITIONS,
} from "../../constants/pdfs";

const AppFooter = ({ setPopupOpenTrue, onChangePdfName }) => {
    const { t } = useTranslation();

    const onClickHandler = (pdfName = PRIVACY_POLICY) => {
        onChangePdfName(pdfName);
        setPopupOpenTrue(true);
    };

    return (
        <footer className="footer">
            <div className="footer__container d-flex">
                {categories.map((category) => (
                    <FooterList key={category.name} data={category} />
                ))}
                <div className="footer__container__col">
                    <span className="footer__container__col__title">
                        {t("home.contactUs")}
                    </span>
                    <ul className="footer__navigation">
                        <li className="footer__navigation__item disabled">
                            <a
                                href="https://mail.google.com/"
                                className="footer__contact__mail"
                            >
                                {contactMail}
                            </a>
                        </li>
                        <li className="footer__navigation__item disabled">
                            <a
                                href={`whatsapp://send?phone=${contactNumber}&text=''`}
                                className="footer__contact__number"
                            >
                                {contactNumber}
                            </a>
                        </li>
                        <li className="footer__navigation__item disabled">
                            <a
                                href={`whatsapp://send?phone=${contactNumber}&text=''`}
                                className="footer__phone__number"
                            >
                                {phoneNumber}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <ul
                className="footer__copyright"
            >
                <li className="footer__copyright_item">
                    {t("common.copyRight.title")}
                </li>
                <li className="footer__copyright_item" onClick={()=>onClickHandler(PRIVACY_POLICY)}>
                    {t("common.copyRight.privacy")}
                </li>
                <li className="footer__copyright_item" onClick={()=>onClickHandler(COOKIES_POLICY)}>
                    {t("common.copyRight.cookies")}
                </li>
                <li className="footer__copyright_item" onClick={()=>onClickHandler(TERMS_CONDITIONS)}>
                    {t("common.copyRight.terms")}
                </li>
            </ul>
        </footer>
    );
};

// href={`email:${contactMail}`}
// href={`tel:${contactNumber}`}

export default AppFooter;
