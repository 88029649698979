import { useTranslation } from "react-i18next";
import React from "react";

import "./ComplianceAndRisk.css";

import ComplianceAndRiskMore from "./ComplianceAndRiskMore";
import ContentBox from "../../common/ContentBox";
import complianceBanner from "../../../assets/media/complianceAndRisk_banner.svg";
import { ViewImage, ViewRightLeft } from "../../View";

const ComplianceAndRisk = () => {
    const { t } = useTranslation();

    return (
        <section>
            <ViewRightLeft>
                <ContentBox
                    title={t("complianceAndRisk.title")}
                    subtitle={""}
                    description={t("complianceAndRisk.description")}
                >
                </ContentBox>
                <ViewImage image={complianceBanner}></ViewImage>
            </ViewRightLeft>
            <ComplianceAndRiskMore />

        </section>
    );
};

export default ComplianceAndRisk;
