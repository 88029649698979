import { useTranslation } from "react-i18next";
import React from "react";

const MissionList = () => {
    const { t } = useTranslation();

    return (
        <ul className="about-us__missions d-flex">
            <li className="mission__item">
                <img src="../images/mission1.svg" alt="mission" className="mission__icon"/>
                <p className="mission__text">{t('aboutUs.mission1')}</p>
            </li>
            <li className="mission__item">
                <img src="../images/mission2.svg" alt="mission" className="mission__icon"/>
                <p className="mission__text">{t('aboutUs.mission2')}</p>
            </li>
            <li className="mission__item">
                <img src="../images/mission3.svg" alt="mission" className="mission__icon"/>
                <p className="mission__text">{t('aboutUs.mission3')}</p>
            </li>
            <li className="mission__item">
                <img src="../images/mission4.svg" alt="mission" className="mission__icon"/>
                <p className="mission__text">{t('aboutUs.mission4')}</p>
            </li>
        </ul>
    )
}

export default MissionList;