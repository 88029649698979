import url from "./url";
const getRatesService = async (data) => {
  const result = await fetch(
    url + "getRateByCountry",
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );
  return result.json();
};

export default getRatesService;
