import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";

import "../../assets/styles/dropdownSub.css";

const DropdownSub = ({ category: { name, link }, subCategories, onClick, mobile }) => {
    const [openedTab, setOpenedTab] = useState(false);

    return (
        <Fragment>
            <div className={`${"navigation__sub__category__container"}`}>
                <Link
                    to={link}
                    className={`navigation__sub__category__name`}
                    onClick={(e) => onClick(e)}
                >
                    {name}
                </Link>
                <div
                    className="navigation__sub__category__toggle__container"
                    onClick={(event) => {
                        event.preventDefault();
                        setOpenedTab(!openedTab);
                    }}
                >
                    <div
                        className={`navigation__sub__category__toggle ${
                            openedTab ? "active" : ""
                        }`}
                    ></div>
                </div>
            </div>

            <ul
                className={`navigation__sub__categories ${
                    openedTab ? "active" : ""
                } ${ mobile ? "mobile_sub"  : "" }`}
            >
                {subCategories.map((cat) => (
                    <li
                        key={cat.id}
                        className={`navigation__sub__item ${
                            cat.isActive && "active__page"
                        }`}
                        onClick={(e) => onClick(e)}
                    >
                        {
                            <Link
                                to={{
                                    pathname: cat.link,
                                    state: cat.name
                                        .replace(" ","")
                                        .toLowerCase(),
                                }}
                               
                            >
                                {cat.name}
                            </Link>
                        }
                    </li>
                ))}
            </ul>
        </Fragment>
    );
};

export default DropdownSub;
