import { useTranslation } from "react-i18next";
import ContactUsBanner from "../../../assets/media/contactUs_banner.svg";
import React, { useState, useEffect } from "react";
import Abutton from "../../common/AButton";
import Loading from "../../common/Loading";
import { ViewRightLeft, ViewImage } from "../../View";
import {
    emailValidation,
    isEmptyValidation,
} from "../../../helpers/Validations";
import InputWithValidation from "../../common/InputWithValidation";
import successImage from "../../../assets/media/success_icon.svg";

import "./ContactUs.css";
import contactUsService from "../../../services/contactUsService";

const ContactUs = () => {
    const { t } = useTranslation();

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [isValid, setIsValid] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: null,
        subject: null,
        fullName: null,
        phone: null,
        message: null,
    }); //this will be used when the api is ready;

    useEffect(() => {
        setFormData((prev) => {
            return {
                ...prev,
                email: email,
                subject: subject,
                fullName: name,
                phone: phone,
                message: message,
            };
        });
    }, [email, subject, name, phone, message]);

    const [validationObj, setValidationObj] = useState({
        name: null,
        email: null,
        subject: null,
        message: null,
    });

    const validation = () => {
        const validationObjTemp = { ...validationObj };
        const boolArray = [];

        for (const key in validationObjTemp) {
            switch (key) {
                case "email":
                    validationObjTemp[key] = emailValidation(email);
                    break;
                case "phone":
                    validationObjTemp[key] = isEmptyValidation(phone);
                    break;
                case "subject":
                    validationObjTemp[key] = isEmptyValidation(subject);
                    break;
                case "name":
                    validationObjTemp[key] = isEmptyValidation(name, "asd");
                    break;
                case "message":
                    validationObjTemp[key] = isEmptyValidation(message);
                    break;
                default:
                    break;
            }
            boolArray.push(validationObjTemp[key]);
        }

        setValidationObj({ ...validationObjTemp });

        if (boolArray.includes(false)) {
            return false;
        } else {
            return true;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (validation()) {
            setTimeout(async () => {
                try {
                    await contactUsService(formData);
                    setIsValid(true);
                } catch (err) {
                    setIsValid(false);
                    console.log(err);
                }
            }, 1000);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <section className="contact-us">
            <div className="blueBackground"></div>
            <ViewRightLeft size = {"sm"}>
                <div className="contact-us__helpBox">
                    <h2 className="contact-us__title title-big light">
                        {t("contactUs.title")}
                    </h2>
                    <p className="contact-us__subtitle">
                        {t("contactUs.subtitle")}
                    </p>
                </div>
                <ViewImage image={ContactUsBanner}></ViewImage>
            </ViewRightLeft>

            {!isValid ? (
                <form
                    className={`contact-us__form d-flex align-center`}
                    onSubmit={(e) => onSubmit(e)}
                >
                    <InputWithValidation
                        inputPlaceholder={t("common.namePlc")}
                        isInputValid={validationObj.name}
                        errorMessage={"*The field is required"}
                        onChangeFunc={setName}
                    />
                    <InputWithValidation
                        inputPlaceholder={t("common.emailPlcForm")}
                        isInputValid={validationObj.email}
                        errorMessage={"*Invalid Email."}
                        onChangeFunc={setEmail}
                    />
                    <InputWithValidation
                        type="phone"
                        inputPlaceholder={t("common.phonePlc")}
                        errorMessage={"*The field is required"}
                        onChangeFunc={setPhone}
                    />
                    <InputWithValidation
                        inputPlaceholder={t("common.subjectPlc")}
                        isInputValid={validationObj.subject}
                        errorMessage={"*The field is required"}
                        onChangeFunc={setSubject}
                    />
                    <InputWithValidation
                        type="textarea"
                        inputPlaceholder={t("common.messagePlc")}
                        isInputValid={validationObj.message}
                        errorMessage={"*The field is required"}
                        onChangeFunc={setMessage}
                    />

                    <div className="form-input-btn">
                        {isLoading ? (
                            <Loading classNames={"center"} />
                        ) : (
                            <Abutton variant={"secondary--solid"} position="center">
                                {t("common.submit")}
                            </Abutton>
                        )}
                    </div>
                </form>
            ) : (
                <div className="success-field">
                    <Abutton variant="secondary--circle" position="center">
                        <img src={successImage} alt="success icon"></img>
                    </Abutton>
                    <span>Message Submitted!</span>
                </div>
            )}
        </section>
    );
};

export default ContactUs;
