import React from "react";
import { useHistory } from "react-router";
import SliderInfo from "../common/SliderInfo";
import AButton from "../common/AButton";
import TriangleRight from "../../assets/media/icons/video_button.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import FirstImage from "../../assets/media/business_first.png";
import SecondImage from "../../assets/media/business_second.png";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Autoplay]);

const Business = () => {
    const history = useHistory();
    const offersList = [
        "Auto compliance check",
        "Standardised reports",
        "24/7 online updates",
        "Managing platform technology",
        "Zero IT overheads",
    ];
    const pagination = {
        clickable: true,
    };

    const btnSettings = {
        function: () => history.push("/company/contact-us"),
        text: "contact us",
    };

    return (
        <>
            <div>
                <Swiper
                    pagination={pagination}
                    allowTouchMove={false}
                    loop={true}
                    // autoplay={{
                    //     "delay": 3500,
                    //     "disableOnInteraction": false,
                    // }}
                >
                    <SwiperSlide>
                        <SliderInfo
                            header="one standard API for all your connections"
                            topInfo="From now on, our standard is your reality!"
                            offersList={offersList}
                            img={FirstImage}
                            btn={btnSettings}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderInfo
                            header="we are here to support you globally"
                            topInfo="Our team will guide you to work together with us"
                            btn={btnSettings}
                            img={SecondImage}
                        />
                    </SwiperSlide>
                </Swiper>
                {/* button with video icon, might be used */}
                {/* <AButton
                    absolute
                    variant="secondary--circle"
                    circleText="See how it’s working."
                >
                    <img src={TriangleRight} style={{ marginLeft: 5 }} alt="TriangleRight" />
            </AButton> */}
            </div>
        </>
    );
};

export default Business;
