import React, { useState } from "react";

import "../../assets/styles/accordion.css";

const Accordion = ({ data, classNames, children }) => {
    const [opened, setOpened] = useState(false);

    return (
        <ul className={`accordion ${classNames}`}>
            <li
                className={`accordion__item ${
                    opened ? "active" : ""
                }`}
            >
                <span
                    className={"accordion__item__title"}
                    onClick={() =>
                        setOpened(!opened)
                    }
                >
                    {data.title}
                </span>
                {children && opened ?  (
                    children
                ) : (
                    <div className="accordion__text__container">
                        <p className="accordion__text">{data.text}</p>
                    </div>
                )}
            </li>
        </ul>
    );
};

export default Accordion;
