import React, { useState } from "react";


const InputWithValidation = ({
    errorMessage,
    inputPlaceholder,
    type = "default",
    isInputValid,
    onChangeFunc,
}) => {
    const [isValid, setIsValid] = useState(isInputValid);
    const [inputValue, setInputValue] = useState("");

    const onChangeHandler = (e) => {
        onChangeFunc(e.target.value);
        setInputValue(e.target.value);
    };
  

    const onPhoneNumberChange = (e) => {
        //input is only numbers and '+'

        const value = e.target.value;
        const allowedChars = "+";
        setIsValid(true);
        if (
            (!isNaN(Number(value)) || value === allowedChars) &&
            !/\s/.test(value)
        ) {
            onChangeFunc(value)
            setInputValue(value);
        }
    };
    const dangerMessage = (
        <span className="form-danger-message">{errorMessage}</span>
    );

    let onInputChange = type === "phone" ? onPhoneNumberChange : onChangeHandler;
    let className =
        type === "textarea"
            ? "form-textarea-container"
            : "form-input-container";

    let inputComponent =
        type === "textarea" ? (
            <textarea
                type="text"
                className="form-input contact-us__input contact-us__message"
                placeholder={inputPlaceholder}
                value={inputValue}
                onChange={(e) => onInputChange(e)}
            ></textarea>
        ) : (
            <input
                type="text"
                className="form-input contact-us__input"
                value={inputValue}
                placeholder={inputPlaceholder}
                onChange={(e) => onInputChange(e)}
            />
        );

    return (
        <div className={className}>
            {inputComponent}
            {isInputValid === false ? dangerMessage : null}
        </div>
    );
};

export default InputWithValidation;
