import React, { useState, useRef } from "react";
import "../../assets/styles/inputWithBtn.css";
import useOnClickOutside from "../../hooks/useOutsideClick";
import Loading from "./Loading";
import locationIcon from "../../assets/media/icons/location.svg";

const searchIcon = "/images/search_icon.svg";

//hardcoded data of countries, will be changed soon

const CountryInput = ({
    placeholder,
    buttonName,
    onSetCountry,
    countriesList,
    goBack,
}) => {
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState();
    const [value, setValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [countries, setCountries] = useState(countriesList);
    const [pos, setPos] = useState(0);

    const wrapperRef = useRef();
    const inputRef = useRef();
    useOnClickOutside(wrapperRef, () => setIsOpen(false));

    const onInputChange = (e) => {
        onSearchHandler();
        setValue(e.target.value);
        setIsValid();
    };
    const onSubmitHandler = (value) => {
        setLoading(true);
        if (formattedCountries.includes(value)) {
            setTimeout(() => {
                setIsValid(true);
                onSetCountry(value);
                setLoading(false);
                goBack(false);
            },2000);
            return true;
        }else{
            setTimeout(() => {
                setIsValid(false);
                setLoading(false);
            },2000);
        }
        return false;
    };

    const onCountryClickHandler = (e) => {
        const countryName = e.target.innerText;
        setValue(countryName);
        setIsOpen(false);
    };

    const onSearchHandler = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };

    let button = (
        <input
            className={`input__withBtn__btn ${
                isValid ? "valid" : ""
            } btn-hover`}
            type={"submit"}
            onClick={() => onSubmitHandler(value)}
            disabled={isValid}
            value={buttonName}
        />
    );

    let loadingIcon = <Loading />;
    let inputPlaceHolder = isValid === false
        ? "Country name is not correct"
        : placeholder;

    const inputHandler = (key) => {
        inputRef.current.focus();
        if (!isOpen) {
            setPos(-1);
        }
        switch (key.code) {
            case "ArrowDown":
                if (pos < formattedCountries.length - 1) {
                    setPos(pos + 1);
                } else {
                    setPos(0);
                }
                break;
            case "ArrowUp":
                if (pos > 0) {
                    setPos(pos - 1);
                } else {
                    setPos(formattedCountries.length - 1);
                }
                break;
            case "Enter":
                if (pos > -1 && pos <= formattedCountries.length - 1) {
                    setValue(formattedCountries[pos]);
                }
                setIsOpen(false);
                setPos(-1);
                onSubmitHandler(formattedCountries[pos]);
                break;
            case "Escape":
                setIsOpen(false);
                break;
            default:
                break;
        }
    };

    function formatCountries() {
        return countries.filter((country) => {
            if (country.toLowerCase().includes(value.toLowerCase())) {
                return country;
            }
            return null;
        });
    }
    const formattedCountries = formatCountries();

    return (
        <div
            ref={wrapperRef}
            className="country__input__container"
            onKeyUp={inputHandler}
        >
            <div className="input__withBtn__box">
                <img
                    src={searchIcon}
                    className={`input__withBtn__icon`}
                    alt="search icon"
                ></img>
                <input
                    type="text"
                    className={`input__withBtn ${
                        isValid === false ? "danger" : ""
                    }`}
                    placeholder={inputPlaceHolder}
                    value={value}
                    onClick={onSearchHandler}
                    onChange={(e) => onInputChange(e)}
                />
                {loading ? loadingIcon : button}
            </div>
            <div className={`selectbox ${isOpen ? "active" : null}`}>
                <ul className="selectbox__items" role="toolbar" ref={inputRef}>
                    {formattedCountries.map((value, index) => {
                        return (
                            <li
                                key={`country_${index}`}
                                className={`selectbox__item ${
                                    index === pos ? "selected" : null
                                }`}
                                onClick={onCountryClickHandler}
                                aria-posinset={index + 1}
                            >
                                <div className="country_name_container">
                                    <img
                                        src={locationIcon}
                                        alt="location icon"
                                    ></img>
                                    {value}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default CountryInput;
