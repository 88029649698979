export const categories = [
    {
        name: "Individuals",
        subCategories: [
            {
                id: 2,
                name: "where we send to",
                link: "/individuals/send-to",
            },
            {
                id: 3,
                name: "wizdraw mobile app",
                link: "/individuals/wizdraw",
            },
        ],
    },
    {
        name: "Business",
        subCategories: [
            {
                id: 1,
                name: "want to partner with us?",
                link: "/business/local-payment",
            },
            {
                id: 2,
                name: "the network",
                link: "/business/network",
            },
            {
                id: 3,
                name: "our solutions",
                categories: [
                    {
                        id: 1,
                        name: "collect Payments",
                        link: "/business/our-solutions/payments",
                    },
                    {
                        id: 2,
                        name: "payout",
                        link: "/business/our-solutions/payout",
                    },
                    {
                        id: 3,
                        name: "marketplace",
                        link: "/business/our-solutions/marketplace",
                    },
                ],
                link: "/business/our-solutions",
            },
            // will be enabled later
            // {
            //     id: 4,
            //     name: "Discover the Future with us",
            //     link: "/business",
            //     disabled: true,
            // },
            // {
            //     id: 5,
            //     name: "Welcome aboard",
            //     link: "/business",
            //     disabled: true,
            // }
        ],
    },

    {
        name: "Company",
        subCategories: [
            {
                id: 1,
                name: "about us",
                link: "/company/about-us",
            },
            {
                id: 2,
                name: "compliance & \n risk management",
                link: "/company/compliance-risk",
            },
            //will be enabled later
            // {
            //     id: 3,
            //     name: "Be a partner / Affiliate",
            //     link: "/company",
            //     disabled: true,
            // },
            // {
            //     id: 4,
            //     name: "For Developers",
            //     link: "/company",
            //     disabled: true,
            // },
            // {
            //     id: 3,
            //     name: "Careers",
            //     link: "/company/careers"
            // },
            // {
            //     id: 4,
            //     name: "FAQ",
            //     link: "/company/faq"
            // },
            {
                id: 3,
                name: "contact us",
                link: "/company/contact-us",
            },
        ],
    },
];

export const availableLanguages = [
    {
        id: 1,
        name: "english",
    },
];
