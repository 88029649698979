import { useTranslation } from "react-i18next";
import React from "react";
import { ViewRightLeft, ViewImage } from "../../View";
import EmailInput from "../../common/EmailInput";
import ContentBox from "../../common/ContentBox";
import SolutionsList from "./SolutionsList";
import InputCard from "../../common/InputCard/InputCard";
import Banner from "../../../assets/media/solutions_banner.svg"
import "./Solutions.css";

const Solutions = () => {
    const { t } = useTranslation();

    const data = [
        {
            subTitle: "",
            description: `With ATS expertise and merchant card acceptance
                          experiences, ATS is working with respective local
                          payment partners to provide trustworthy bespoke
                          payment solutions. You will benefit from saving
                          your time and resources to select the right payment
                          solutions that fit your business needs!`,
        },
    ];

    return (
        <section>
            <ViewRightLeft>
                <div className="solutions">
                    <ContentBox
                        title={t("solutions.title")}
                        description={t("solutions.description",{returnObjects : true})}
                        data={data}
                    />
                </div>
                <ViewImage image={Banner}></ViewImage>
            </ViewRightLeft>

            <SolutionsList />

            {/* <div className="solutions__joinBox">
                <div className="solutions__joinBox__container">
                    <h2 className="solutions__joinBox__title title-big light">
                        {t('solutions.readyTo')}<span className="text-green">{t('solutions.join')}</span>
                    </h2>
                    <p className="solutions__joinBox__subtitle">{t('solutions.joinSubTitle')}</p>
                </div>
            </div> */}
            <InputCard
                type={"mail-box"}
                title={t("common.readyTo")}
                titleGreen={t("solutions.join") + "?"}
                subtitle={t("solutions.joinSubTitle")}
            >
                <EmailInput
                    placeholder={t("common.emailPlc")}
                    buttonName={t("solutions.join")}
                />
            </InputCard>
        </section>
    );
};

export default Solutions;
