import React from "react";
import styles from "./InputCard.module.scss";
import airplaneBlue from "../../../assets/media/airplaneBlue.svg";
import airplaneWhite from "../../../assets/media/airplaneWhite.svg";
import bannerSelectBox from "../../../assets/media/manWithBinocular.svg";
import bannerMailBox from "../../../assets/media/girlWithLaptop.svg";
import PropTypes from "prop-types";

const TYPES = ["select-box", "mail-box", "country-box", "blank"];

const images = {
    "select-box": {
        airplanes: [airplaneBlue, airplaneBlue, airplaneWhite],
        bannerImg: bannerMailBox,
    },
    "country-box": {
        airplanes: [airplaneBlue, airplaneWhite, airplaneBlue],
        bannerImg: bannerSelectBox,
    },
    "mail-box": {
        bgImage: "",
        bannerImg: "",
        airplanes: [],
    },
    blank: {
        bgImage: "",
        bannerImg: "",
        airplanes: [],
    },
};

const InputCard = ({ title, titleGreen, subtitle, type, children }) => {
    return (
        <div className={`${styles["input_card_container"]}  ${styles[type]}`}>
            <div className={`${styles.airplanes}`}>
                {images[type].airplanes.map((img, index) => {
                    return (
                        <img
                            key={`airplane_${index}`}
                            className={`${styles.bgImg} ${
                                styles["airplane_" + index]
                            }`}
                            src={img}
                            alt="airplanes"
                        ></img>
                    );
                })}
            </div>
            <div className={styles.title}>
                <p className={styles.lg}>{title}</p>
                <p className={styles["title--green"]}>{titleGreen}</p>
            </div>
            <p className={styles.subtitle}>{subtitle}</p>
            <div style={styles.banner}>
                <img
                    className={styles.bannerImg}
                    src={images[type].bannerImg}
                    alt="banner_image"
                ></img>
            </div>
            <div className={styles.children}>{children}</div>
        </div>
    );
};

export default InputCard;

InputCard.propTypes = {
    title: PropTypes.string,
    titleGreen: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.oneOf(TYPES),
};

InputCard.defaultProps = {
    title: "How can we",
    titleGreen: "",
    subtitle: "",
    type: "select-box",
};
