import React from "react";

import Card from "../../common/Card";

const SolutionsList = () => {
    const data = [
        {
            name: "security",
            title: "security",
            description: "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care"
        },
        {
            name: "accepted_payments",
            title: "accepted payment methods",
            description: "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network..."
        },
        {
            name: "compatibility",
            title: "compatibility",
            description: "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements."
        },
        {
            name: "payment_location",
            title: "payment location",
            description: "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers."
        },
        {
            name: "cost",
            title: "cost",
            description: "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay."
        }
    ];

    return (
        <ul className="solutions__cardBox d-flex">
            {data.map(solution => <li key={solution.name}>
                <Card card={solution}/>
            </li>)}
        </ul>
    )
};

export default SolutionsList;