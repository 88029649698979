import React, { forwardRef } from "react";
import styles from "./ViewRightLeft.module.scss";
import { oneOf } from "prop-types";

const ViewRightLeft = forwardRef(({ children, reversed, size },ref) => {
    return (
        <div
            className={`${styles["container"]} ${
                reversed ? styles["reversed"] : ""
            } ${styles[size]}`}
            ref={ref}
        >
            {children}
        </div>
    );
});

ViewRightLeft.propTypes = {
    reversed: oneOf([true, false]),
    size : oneOf(["sm"])
};

ViewRightLeft.defaultProps = {
    reversed: false,
};

export default ViewRightLeft;
