export const Translation = {
    english: {
        translation: {
            home: {
                "title": "consolidate your international transactions",
                "description": [`Integrate our complete suite for modern finance  and allow 
                                your clients a world class cross border fund settlement 
                                experience `, `Get instant deposits, immediate feedback, lowest pricing, 
                                and awesome benefits`, `Start connecting supply with demand in a single click`],
                "businessBtn": "For Businesses",
                "individualBtn": "For Individuals",
                "findUs": "You can find us here",
                "contactUs": "contact us"
            },
            sendTo: {
                "title": "what's your transaction destination?",
                "description": "",
                "searchPlc": "Search country",
                "searchBtn": "Search",
            },
            wizdraw: {
                "subtitle": "our white label mobile app",
                "description": [`It's   a   modular,   scalable   software   on   the   cloud,   which   our   clients   usually   launch   within   an
                                average of 3 months The solution is cost effective and fully customisable, and supports more
                                than   40   currencies   worldwide,   with   advanced   currency   conversion   engine   powered   by   live
                                market feeds, cutting-edge funds sending modules which enable sending to friends from the
                                address book, QR executable payments, payment cards, and a lot more.`,
                    `Highly   modular   application,   innovative,   proven   and   robust   white-labelled   technology
                                platform, comprehensive compliance management tools for tracking and assessing including
                                name checks and reporting.`],
                "downloadBtn": "Download now, and join our happy family"
            },
            localPayment: {
                "title": "at AT Services, we work for you!",
                "subTitle": "at AT Services, we work for you!",
                "description": [`Benefit from saving time and resources by select the right payment solutions to fit
                                your business needs. We have a service platform that can integrate easily with any
                                local payment's provider. Collect payments more efficiently with us !`]
            },
            network: {
                "title": "the network",
                "subTitle": "We never stop building our worldwide payments network",
                "emailGreenTxt": "partner with us?",
                "description": [`We   never   stop   building   our   worldwide   payments   network,   which   currently   covers   most
                                currencies   in   APAC,   Central   Asia,   Russia   and   the   whole   of   Europe.  
                                We act for you, the merchant, to reach any potential customer worldwide.`,
                    `Our   system   allows   collection   of   funds,   which   you   will   find   valuable   when   carrying   out
                                international commerce. Our API's feedback to your systems and to end clients the status of
                                each transaction. Our dedicated account managers follow up and are constantly in touch with
                                any requirements our mechanist have`]
            },
            solutions: {
                "title": "our solutions",
                "subTitle": "our solutions",
                "description": [`We specialise in enterprise level payment technology that is flexible enough for businesses of all shapes and sizes, 
                                to accept and process payments.`, `Our software 
                                suits is tailored for large array of clients 
                                - from eCommerce online stores through 
                                to subscription businesses, platforms 
                                marketplaces, exporters, importers which 
                                make salary payments, pensions, bonuses, 
                                invoices settlements and etc.`, `We offer an end - to - 
                                end software solution for all your payment needs with 
                                multiple channels to choose from.`],
                "securityTitle": "Security",
                "securityDescription": "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care",
                "paymentMethodsTitle": "Accepted Payment Methods",
                "paymentMethodsDescription": "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network...",
                "compatibilityTitle": "Compatibility",
                "compatibilityDescription": "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements.",
                "paymentLocationTitle": "Payment Location",
                "paymentLocationDescription": "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers.",
                "costTitle": "Cost",
                "costDescription": "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay.",
                "readyTo": "want to",
                "join": "join",
                "joinSubTitle": "join us, and leave the rest to us"
            },
            payments: {
                "title": "collect payments",
                "subTitle": "More Customers More Places Our Platform to Accept Any Payment",
                "description": "We will Collect for you local payments in cash, POS, bank transfers, credit, and debit cards, and digital wallets.We will supply a portal to view reconciliation, reporting, and fund settlement of 40+ currencies, including online UI for sending payments worldwide.",
                "hiddenSubTitle": "Fraud Management and Compliance",
                "hiddenDescription": "We offer Risk management, Compliance, and Identity Verification ATS also keeps you compliant with AML, CTF, sanctions screening, KYC and KYB identity verification.",
                "payout": [{ "text": "You can now disburse funds to banks, in the" }, { "text_underline": "30 plus nations in Asia" }, { "text": "ATS operates in" }],
                "marketplace": {
                    textsWithIcon: ["grow your business globally", "track all of your payment activity in one place"],
                    textGreen: "FASTER THAN SWIFT",
                    textGreenBackground: "Quick and Simple",
                    text: "Pay to your contractors, freelancers and suppliers all over Asia"
                }
            },
            complianceAndRisk: {
                "title": "compliance &\n risk management",
                "description": "The Company’s risk management processes and technology are a central part of its business. The Company uses a risk management system that allows it to analyse a range of data to prevent and detect compliance issues and fraud, including pull-API, transaction matching, and account reconciliation, external third-party auditing, and sending limits. The Company’s risk management technology helps to manage the primary risk challenges that confront its business.",
                "titleMore": "company compliance and risk management innovation",
                "descriptionMore": ["Blockchain Compliance System. The Company has created an innovative new compliance system, which is intended to be linked via API connection to the Transaction Management System, using advanced blockchain technology. The Company refers to this system as its “blockchain compliance system”. It is based on the Hyperledger Fabric protocol.",
                    "The project aims to bring together a number of independent efforts to develop open protocols and standards, by providing a modular framework that supports different components for different uses, including blockchains with their own consensus and storage models, and services for identity, access control, and contracts.",
                    "The Company’s blockchain compliance system tracks the amount of each customer’s remittance, the date, time and location of the remittance, and all KYC documentation related to the customer and the beneficiary, and provide relevant information, on a permissioned basis, to the Company’s remittance network, including depository and correspondent banks, as well as governmental authorities."
                ],
                "titlePrinciples": "our anti money laundry principles",
                "p1": "Payment and Fraud Risk.",
                "p3": "Collection of each user’s valid national ID, proof of address and personal photo.",
                "p5": "A limitation on the maximum amount permitted to be remitted by any single customer per month.",
                "p2": "Anti-Money Laundering and Regulatory Compliance.",
                "p4": "Screening of each customer’s name against international terrorist, criminal and other lists.",
                "p6": "Quarterly compulsory reporting to relevant regulators. On-going AML training for personnel.",
            },
            careers: {
                "title": "ATS Create Global Opportunities",
                "description": "We are on a mission to deliver Migrant workers money  to their family back home fast, secure, and easy. ATS is a great place to work with a family culture.  With Activty in Hong Kong, Singapore, Philippines and UK, you will enjoy an international envirment. ATS has a strong team and employee culture.  You’ll work with inspiring people and be an essential part of the team.",
                "subTitle": "In ATS we Believe In People"
            },
            contactUs: {
                "title": "contact us",
                "titleGreen": "Help?",
                "subtitle": "Send us a message and tell us how we can help, and how we can reach you."
            },
            faq: {
                "title": "How can we",
                "titleGreen": "help you?"
            },
            aboutUs: {
                "title": "about us",
                "titleWhyChoose": "why choose us",
                "subTitle": "AT Services Ltd is a company incorporated in Hong Kong in 2015",
                "description": [`AT Services provides you with knowledge 
                                and expertise, ability and a professional mindset that is only gained at the highest levels in the foreign exchange markets. 
                                The world is our market, but we are located in London, Yerevan and Mauritius,  innovation hubs which cover two continents.`,
                    `AT Services is founded and run by people with extensive experience in the payments market and consists of a crew of dedicated, 
                                hard working and katnowledgeable people. All here to make payments easy by continuously focusing on innovation and improving existing solutions.`,
                    `Our mission is to provide outstanding exchange rates, fastest and most secure overseas payment services with efficient and friendly customer service 
                                that people will want to recommend to others.`,
                    `AT Services offers a personal and informed foreign currency exchange facility. We replace the traditional 
                                role of banks in the money exchange transaction, and in doing so, offer a higher level of currency analysis and service not normally found with the banks. 
                                We will save you a great deal through commission free transactions and better exchange rates.`,
                    `AT Services has clients in 53 countries and last year made 
                                payments into 95 jurisdictions. We have that global reach and experience that our clients rely on to enhance their own business. 
                                We are your global foreign exchange and payments partner.
                            `],
                "descriptionWhyChoose": "We are proud of our cross-cultural leadership, and it helps us better understand the needs of our customers from all over the world. The second thing is that we are a team of payment and technology professionals with more than 50 years of experience in the banking and financial services sector. We use the most advantageous systems that technology has to offer to protect you. We are regulated wherever we are located, and our partners are only Banks and regulated financial institutes.",
                "titleMissions": "our mission",
                "mission1": "To offer the best currency exchange rates and lowest charges.",
                "mission2": "To provide fast, safe, secure, convenient, and reliable means of making payments and money transfer.",
                "mission3": "To provide highly transparent, affordable well-detailed remittance and currency exchange services.",
                "mission4": "To offer zero tolerance to fraud and money laundering.",
                "titlePayers": "Our Payers",
            },
            common: {
                "learnMoreBtn": "Learn More",
                "copyRight": { title: `© ${new Date().getFullYear()} COPYRIGHT`, privacy: "Privacy Policy", cookies: "Cookies Policy", terms: "Terms & Conditions" },
                "emailPlc": "Enter your mail",
                "namePlc": "Full Name",
                "emailPlcForm": "Email",
                "phonePlc": "Phone No. (optional)",
                "subjectPlc": "Subject",
                "messagePlc": "Message",
                "selectPlc": "Please Choose",
                "submit": "Submit",
                "readyTo": "ready to",
                "partner": "partner with us?",
                "join": "join",
                "isUseful": "Was the article useful to you?"
            }
        }
    },
    indian: {
        translation: {
            home: {
                "title": "RELIABLE TRUSTED REMITTANCE WE HELP SAVE COST",
                "businessBtn": "For Businesses",
                "individualBtn": "For Individuals",
                "findUs": "You can find us here",
                "contactUs": "contact us"
            },
            sendTo: {
                "title": "Where we send to",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse praesent at tellus sed vitae. Est semper elementum, vitae vel lectus. Arcu velit lobortis metus, tempor, elementum eu. Nulla senectus eget nisi, arcu malesuada.",
                "searchPlc": "Search country",
                "searchBtn": "Search",
            },
            wizdraw: {
                "title": "Wizdraw Mobile APP",
                "title1": "With WIZDRAW App",
                "description1": "Our mission is to empower migrant workers and their families to become financially literate and in control using innovative, app-based technologies",
                "title2": "WIZDRAW App",
                "description2": "Revolutionary version give senders the ability to send foreign currency and the beneficiaries the ability to shop the best rate locally or choose the best remittance location based on their selected criteria.",
                "title3": "WIZDRAW App",
                "description3": "Plan involves the creation of a number of in-app features that will use machine learning to educate users and encourage good financial decisions.",
                "downloadBtn": "Download now, and join our happy family"
            },
            localPayment: {
                "title": "at AT Services, we work for you!",
                "subTitle": "at AT Services, we work for you!",
                "description": "With ATS, you will benefit from saving your time and resources to select the right payment solutions to fit your business needs. We have a service platform that can integrate easily with any local payment's provider. Collect payments more efficiently with ATS."
            },
            network: {
                "title": "the network",
                "subTitle": "We never stop building our worldwide payments network",
                "description": "ATS acting for you, the merchant, to reach any potential customer worldwide, and you will be able to let your potential customers pay in any way they want and get paid according to their choice anywhere."
            },
            solutions: {
                "title": "our solutions",
                "subTitle": "our solutions",
                "description": [`We specialise in enterprise level payment technology that is flexible enough for businesses of all shapes and sizes, 
                                to accept and process payments.`, `Our software 
                                suits is tailored for large array of clients 
                                - from eCommerce online stores through 
                                to subscription businesses, platforms 
                                marketplaces, exporters, importers which 
                                make salary payments, pensions, bonuses, 
                                invoices settlements and etc.`, `We offer an end - to - 
                                end software solution for all your payment needs with 
                                multiple channels to choose from.`], "securityTitle": "Security",
                "securityDescription": "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care",
                "paymentMethodsTitle": "Accepted Payment Methods",
                "paymentMethodsDescription": "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network...",
                "compatibilityTitle": "Compatibility",
                "compatibilityDescription": "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements.",
                "paymentLocationTitle": "Payment Location",
                "paymentLocationDescription": "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers.",
                "costTitle": "Cost",
                "costDescription": "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay.",
                "readyTo": "want to",
                "join": "join",
                "joinSubTitle": "join us, and leave the worrying to us"
            },
            complianceAndRisk: {
                "title": "compliance & \n risk management",
                "description": "The Company’s risk management processes and technology are a central part of its business. The Company uses a risk management system that allows it to analyse a range of data to prevent and detect compliance issues and fraud, including pull-API, transaction matching, and account reconciliation, external third-party auditing, and sending limits. The Company’s risk management technology helps to manage the primary risk challenges that confront its business.",
                "titleMore": "company compliance and risk management innovation",
                "descriptionMore": "Blockchain Compliance System. The Company has created an innovative new compliance system, which is intended to be linked via API connection to the Transaction Management System, using advanced blockchain technology. The Company refers to this system as its “blockchain compliance system”. It is based on the Hyperledger Fabric protocol. The project aims to bring together a number of independent efforts to develop open protocols and standards, by providing a modular framework that supports different components for different uses, including blockchains with their own consensus and storage models, and services for identity, access control, and contracts.  The Company’s blockchain compliance system tracks the amount of each customer’s remittance, the date, time and location of the remittance, and all KYC documentation related to the customer and the beneficiary, and provide relevant information, on a permissioned basis, to the Company’s remittance network, including depository and correspondent banks, as well as governmental authorities.",
                "titlePrinciples": "our anti money laundry principles",
                "p1": "Payment and Fraud Risk.",
                "p3": "Collection of each user’s valid national ID, proof of address and personal photo.",
                "p5": "A limitation on the maximum amount permitted to be remitted by any single customer per month.",
                "p2": "Anti-Money Laundering and Regulatory Compliance.",
                "p4": "Screening of each customer’s name against international terrorist, criminal and other lists.",
                "p6": "Quarterly compulsory reporting to relevant regulators. On-going AML training for personnel.",
            },
            careers: {
                "title": "ATS Create Global Opportunities",
                "description": "We are on a mission to deliver Migrant workers money  to their family back home fast, secure, and easy. ATS is a great place to work with a family culture.  With Activty in Hong Kong, Singapore, Philippines and UK, you will enjoy an international envirment. ATS has a strong team and employee culture.  You’ll work with inspiring people and be an essential part of the team.",
                "subTitle": "In ATS we Believe In People"
            },
            contactUs: {
                "title": "contact us",
                "titleGreen": "Help?",
                "subtitle": "Send us a message and tell us how we can help, and how we can reach you."
            },
            faq: {
                "title": "How can we",
                "titleGreen": "help you?"
            },
            aboutUs: {
                "title": "about us",
                "titleWhyChoose": "why choose us",
                "subTitle": "AT Services Ltd is a company incorporated in Hong Kong in 2015",
                "description": "AT Services provides you with knowledge and expertise, professionalism and ability that is only gained at the highest levels in the foreign exchange markets. Our mission is to provide outstanding exchange rates, the fastest and most secure overseas payment services, efficient and friendly customer service that people will want to recommend to others AT Services offers a personal and informed foreign currency exchange facility. We replace the traditional role of the bank in the money exchange transaction, and in doing so, offer a higher level of currency analysis and service not normally found with the banks. We will save you money through commission free transactions and better exchange rates. AT Services has clients in 53 countries and last year made payments into 95 jurisdictions. We have that global reach and experience that our clients rely on to enhance their own business. We are your global foreign exchange and payments partner.",
                "descriptionWhyChoose": "We are proud of our cross-cultural leadership, and it helps us better understand the needs of our customers from all over the world. The second thing is that we are a team of payment and technology professionals with more than 50 years of experience in the banking and financial services sector. We use the most advantageous systems that technology has to offer to protect you. We are regulated wherever we are located, and our partners are only Banks and regulated financial institutes.",
                "titleMissions": "our mission",
                "mission1": "To offer the best currency exchange rates and lowest charges.",
                "mission2": "To provide fast, safe, secure, convenient, and reliable means of making payments and money transfer.",
                "mission3": "To provide highly transparent, affordable well-detailed remittance and currency exchange services.",
                "mission4": "To offer zero tolerance to fraud and money laundering.",
                "titlePayers": "Our Payers",
            },
            common: {
                "learnMoreBtn": "Learn More",
                "copyRight": { title: "© 2021 COPYRIGHT", privacy: "Privacy Policy", cookies: "Cookies Policy", terms: "Terms & Conditions" },
                "emailPlc": "Enter your mail",
                "namePlc": "Full Name",
                "emailPlcForm": "Email",
                "phonePlc": "Phone No. (optional)",
                "subjectPlc": "Subject",
                "messagePlc": "Message",
                "selectPlc": "Please Choose",
                "submit": "Submit",
                "isUseful": "Was the article useful to you?"
            }
        }
    },
    thailand: {
        translation: {
            home: {
                "title": "RELIABLE TRUSTED REMITTANCE WE HELP SAVE COST",
                "businessBtn": "For Businesses",
                "individualBtn": "For Individuals",
                "findUs": "You can find us here",
                "contactUs": "contact us"
            },
            sendTo: {
                "title": "Where we send to",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse praesent at tellus sed vitae. Est semper elementum, vitae vel lectus. Arcu velit lobortis metus, tempor, elementum eu. Nulla senectus eget nisi, arcu malesuada.",
                "searchPlc": "Search country",
                "searchBtn": "Search",
            },
            wizdraw: {
                "title": "Wizdraw Mobile APP",
                "title1": "With WIZDRAW App",
                "description1": "Our mission is to empower migrant workers and their families to become financially literate and in control using innovative, app-based technologies",
                "title2": "WIZDRAW App",
                "description2": "Revolutionary version give senders the ability to send foreign currency and the beneficiaries the ability to shop the best rate locally or choose the best remittance location based on their selected criteria.",
                "title3": "WIZDRAW App",
                "description3": "Plan involves the creation of a number of in-app features that will use machine learning to educate users and encourage good financial decisions.",
                "downloadBtn": "Download now, and join our happy family"
            },
            localPayment: {
                "title": "Local Payment Experience",
                "subTitle": "at AT Services, we work for you!",
                "description": "With ATS, you will benefit from saving your time and resources to select the right payment solutions to fit your business needs. We have a service platform that can integrate easily with any local payment's provider. Collect payments more efficiently with ATS."
            },
            network: {
                "title": "the network",
                "subTitle": "We never stop building our worldwide payments network",
                "description": "ATS acting for you, the merchant, to reach any potential customer worldwide, and you will be able to let your potential customers pay in any way they want and get paid according to their choice anywhere."
            },
            solutions: {
                "title": "our solutions",
                "subTitle": "our solutions",
                "description": [`We specialise in enterprise level payment technology that is flexible enough for businesses of all shapes and sizes, 
                                to accept and process payments.`, `Our software 
                                suits is tailored for large array of clients 
                                - from eCommerce online stores through 
                                to subscription businesses, platforms 
                                marketplaces, exporters, importers which 
                                make salary payments, pensions, bonuses, 
                                invoices settlements and etc.`, `We offer an end - to - 
                                end software solution for all your payment needs with 
                                multiple channels to choose from.`],
                "securityTitle": "Security",
                "securityDescription": "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care",
                "paymentMethodsTitle": "Accepted Payment Methods",
                "paymentMethodsDescription": "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network...",
                "compatibilityTitle": "Compatibility",
                "compatibilityDescription": "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements.",
                "paymentLocationTitle": "Payment Location",
                "paymentLocationDescription": "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers.",
                "costTitle": "Cost",
                "costDescription": "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay.",
                "readyTo": "want to",
                "join": "join",
                "joinSubTitle": "join us, and leave the worrying to us"
            },
            complianceAndRisk: {
                "title": "compliance & \n risk management",
                "description": "The Company’s risk management processes and technology are a central part of its business. The Company uses a risk management system that allows it to analyse a range of data to prevent and detect compliance issues and fraud, including pull-API, transaction matching, and account reconciliation, external third-party auditing, and sending limits. The Company’s risk management technology helps to manage the primary risk challenges that confront its business.",
                "titleMore": "company compliance and risk management innovation",
                "descriptionMore": "Blockchain Compliance System. The Company has created an innovative new compliance system, which is intended to be linked via API connection to the Transaction Management System, using advanced blockchain technology. The Company refers to this system as its “blockchain compliance system”. It is based on the Hyperledger Fabric protocol. The project aims to bring together a number of independent efforts to develop open protocols and standards, by providing a modular framework that supports different components for different uses, including blockchains with their own consensus and storage models, and services for identity, access control, and contracts.  The Company’s blockchain compliance system tracks the amount of each customer’s remittance, the date, time and location of the remittance, and all KYC documentation related to the customer and the beneficiary, and provide relevant information, on a permissioned basis, to the Company’s remittance network, including depository and correspondent banks, as well as governmental authorities.",
                "titlePrinciples": "our anti money laundry principles",
                "p1": "Payment and Fraud Risk.",
                "p3": "Collection of each user’s valid national ID, proof of address and personal photo.",
                "p5": "A limitation on the maximum amount permitted to be remitted by any single customer per month.",
                "p2": "Anti-Money Laundering and Regulatory Compliance.",
                "p4": "Screening of each customer’s name against international terrorist, criminal and other lists.",
                "p6": "Quarterly compulsory reporting to relevant regulators. On-going AML training for personnel.",
            },
            careers: {
                "title": "ATS Create Global Opportunities",
                "description": "We are on a mission to deliver Migrant workers money  to their family back home fast, secure, and easy. ATS is a great place to work with a family culture.  With Activty in Hong Kong, Singapore, Philippines and UK, you will enjoy an international envirment. ATS has a strong team and employee culture.  You’ll work with inspiring people and be an essential part of the team.",
                "subTitle": "In ATS we Believe In People"
            },
            contactUs: {
                "title": "contact us",
                "titleGreen": "Help?",
                "subtitle": "Send us a message and tell us how we can help, and how we can reach you."
            },
            faq: {
                "title": "How can we",
                "titleGreen": "help you?"
            },
            aboutUs: {
                "title": "about us",
                "titleWhyChoose": "why choose us",
                "subTitle": "AT Services Ltd is a company incorporated in Hong Kong in 2015",
                "description": "AT Services provides you with knowledge and expertise, professionalism and ability that is only gained at the highest levels in the foreign exchange markets. Our mission is to provide outstanding exchange rates, the fastest and most secure overseas payment services, efficient and friendly customer service that people will want to recommend to others AT Services offers a personal and informed foreign currency exchange facility. We replace the traditional role of the bank in the money exchange transaction, and in doing so, offer a higher level of currency analysis and service not normally found with the banks. We will save you money through commission free transactions and better exchange rates. AT Services has clients in 53 countries and last year made payments into 95 jurisdictions. We have that global reach and experience that our clients rely on to enhance their own business. We are your global foreign exchange and payments partner.",
                "descriptionWhyChoose": "We are proud of our cross-cultural leadership, and it helps us better understand the needs of our customers from all over the world. The second thing is that we are a team of payment and technology professionals with more than 50 years of experience in the banking and financial services sector. We use the most advantageous systems that technology has to offer to protect you. We are regulated wherever we are located, and our partners are only Banks and regulated financial institutes.",
                "titleMissions": "our mission",
                "mission1": "To offer the best currency exchange rates and lowest charges.",
                "mission2": "To provide fast, safe, secure, convenient, and reliable means of making payments and money transfer.",
                "mission3": "To provide highly transparent, affordable well-detailed remittance and currency exchange services.",
                "mission4": "To offer zero tolerance to fraud and money laundering.",
                "titlePayers": "Our Payers",
            },
            common: {
                "learnMoreBtn": "Learn More",
                "copyRight": { title: "© 2021 COPYRIGHT", privacy: "Privacy Policy", cookies: "Cookies Policy", terms: "Terms & Conditions" },
                "emailPlc": "Enter your mail",
                "namePlc": "Full Name",
                "emailPlcForm": "Email",
                "phonePlc": "Phone No. (optional)",
                "subjectPlc": "Subject",
                "messagePlc": "Message",
                "selectPlc": "Please Choose",
                "submit": "Submit",
                "isUseful": "Was the article useful to you?"
            }
        }
    },
    sri_lanka: {
        translation: {
            home: {
                "title": "RELIABLE TRUSTED REMITTANCE WE HELP SAVE COST",
                "businessBtn": "For Businesses",
                "individualBtn": "For Individuals",
                "findUs": "You can find us here",
                "contactUs": "contact us"
            },
            sendTo: {
                "title": "Where we send to",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse praesent at tellus sed vitae. Est semper elementum, vitae vel lectus. Arcu velit lobortis metus, tempor, elementum eu. Nulla senectus eget nisi, arcu malesuada.",
                "searchPlc": "Search country",
                "searchBtn": "Search",
            },
            wizdraw: {
                "title": "Wizdraw Mobile APP",
                "title1": "With WIZDRAW App",
                "description1": "Our mission is to empower migrant workers and their families to become financially literate and in control using innovative, app-based technologies",
                "title2": "WIZDRAW App",
                "description2": "Revolutionary version give senders the ability to send foreign currency and the beneficiaries the ability to shop the best rate locally or choose the best remittance location based on their selected criteria.",
                "title3": "WIZDRAW App",
                "description3": "Plan involves the creation of a number of in-app features that will use machine learning to educate users and encourage good financial decisions.",
                "downloadBtn": "Download now, and join our happy family"
            },
            localPayment: {
                "title": "at AT Services, we work for you!",
                "subTitle": "at AT Services, we work for you!",
                "description": "With ATS, you will benefit from saving your time and resources to select the right payment solutions to fit your business needs. We have a service platform that can integrate easily with any local payment's provider. Collect payments more efficiently with ATS."
            },
            network: {
                "title": "the network",
                "subTitle": "We never stop building our worldwide payments network",
                "description": "ATS acting for you, the merchant, to reach any potential customer worldwide, and you will be able to let your potential customers pay in any way they want and get paid according to their choice anywhere."
            },
            solutions: {
                "title": "our solutions",
                "subTitle": "our solutions",
                "description": [`We specialise in enterprise level payment technology that is flexible enough for businesses of all shapes and sizes, 
                                to accept and process payments.`, `Our software 
                                suits is tailored for large array of clients 
                                - from eCommerce online stores through 
                                to subscription businesses, platforms 
                                marketplaces, exporters, importers which 
                                make salary payments, pensions, bonuses, 
                                invoices settlements and etc.`, `We offer an end - to - 
                                end software solution for all your payment needs with 
                                multiple channels to choose from.`],
                "securityTitle": "Security",
                "securityDescription": "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care",
                "paymentMethodsTitle": "Accepted Payment Methods",
                "paymentMethodsDescription": "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network...",
                "compatibilityTitle": "Compatibility",
                "compatibilityDescription": "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements.",
                "paymentLocationTitle": "Payment Location",
                "paymentLocationDescription": "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers.",
                "costTitle": "Cost",
                "costDescription": "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay.",
                "readyTo": "want to",
                "join": "join",
                "joinSubTitle": "join us, and leave the worrying to us"
            },
            complianceAndRisk: {
                "title": "compliance & \n risk management",
                "description": "The Company’s risk management processes and technology are a central part of its business. The Company uses a risk management system that allows it to analyse a range of data to prevent and detect compliance issues and fraud, including pull-API, transaction matching, and account reconciliation, external third-party auditing, and sending limits. The Company’s risk management technology helps to manage the primary risk challenges that confront its business.",
                "titleMore": "company compliance and risk management innovation",
                "descriptionMore": "Blockchain Compliance System. The Company has created an innovative new compliance system, which is intended to be linked via API connection to the Transaction Management System, using advanced blockchain technology. The Company refers to this system as its “blockchain compliance system”. It is based on the Hyperledger Fabric protocol. The project aims to bring together a number of independent efforts to develop open protocols and standards, by providing a modular framework that supports different components for different uses, including blockchains with their own consensus and storage models, and services for identity, access control, and contracts.  The Company’s blockchain compliance system tracks the amount of each customer’s remittance, the date, time and location of the remittance, and all KYC documentation related to the customer and the beneficiary, and provide relevant information, on a permissioned basis, to the Company’s remittance network, including depository and correspondent banks, as well as governmental authorities.",
                "titlePrinciples": "our anti money laundry principles",
                "p1": "Payment and Fraud Risk.",
                "p3": "Collection of each user’s valid national ID, proof of address and personal photo.",
                "p5": "A limitation on the maximum amount permitted to be remitted by any single customer per month.",
                "p2": "Anti-Money Laundering and Regulatory Compliance.",
                "p4": "Screening of each customer’s name against international terrorist, criminal and other lists.",
                "p6": "Quarterly compulsory reporting to relevant regulators. On-going AML training for personnel.",
            },
            careers: {
                "title": "ATS Create Global Opportunities",
                "description": "We are on a mission to deliver Migrant workers money  to their family back home fast, secure, and easy. ATS is a great place to work with a family culture.  With Activty in Hong Kong, Singapore, Philippines and UK, you will enjoy an international envirment. ATS has a strong team and employee culture.  You’ll work with inspiring people and be an essential part of the team.",
                "subTitle": "In ATS we Believe In People"
            },
            contactUs: {
                "title": "contact us",
                "titleGreen": "Help?",
                "subtitle": "Send us a message and tell us how we can help, and how we can reach you."
            },
            faq: {
                "title": "How can we",
                "titleGreen": "help you?"
            },
            aboutUs: {
                "title": "about us",
                "titleWhyChoose": "why choose us",
                "subTitle": "AT Services Ltd is a company incorporated in Hong Kong in 2015",
                "description": "AT Services provides you with knowledge and expertise, professionalism and ability that is only gained at the highest levels in the foreign exchange markets. Our mission is to provide outstanding exchange rates, the fastest and most secure overseas payment services, efficient and friendly customer service that people will want to recommend to others AT Services offers a personal and informed foreign currency exchange facility. We replace the traditional role of the bank in the money exchange transaction, and in doing so, offer a higher level of currency analysis and service not normally found with the banks. We will save you money through commission free transactions and better exchange rates. AT Services has clients in 53 countries and last year made payments into 95 jurisdictions. We have that global reach and experience that our clients rely on to enhance their own business. We are your global foreign exchange and payments partner.",
                "descriptionWhyChoose": "We are proud of our cross-cultural leadership, and it helps us better understand the needs of our customers from all over the world. The second thing is that we are a team of payment and technology professionals with more than 50 years of experience in the banking and financial services sector. We use the most advantageous systems that technology has to offer to protect you. We are regulated wherever we are located, and our partners are only Banks and regulated financial institutes.",
                "titleMissions": "our mission",
                "mission1": "To offer the best currency exchange rates and lowest charges.",
                "mission2": "To provide fast, safe, secure, convenient, and reliable means of making payments and money transfer.",
                "mission3": "To provide highly transparent, affordable well-detailed remittance and currency exchange services.",
                "mission4": "To offer zero tolerance to fraud and money laundering.",
                "titlePayers": "Our Payers",
            },
            common: {
                "learnMoreBtn": "Learn More",
                "copyRight": { title: "© 2021 COPYRIGHT", privacy: "Privacy Policy", cookies: "Cookies Policy", terms: "Terms & Conditions" },
                "emailPlc": "Enter your mail",
                "namePlc": "Full Name",
                "emailPlcForm": "Email",
                "phonePlc": "Phone No. (optional)",
                "subjectPlc": "Subject",
                "messagePlc": "Message",
                "selectPlc": "Please Choose",
                "submit": "Submit",
                "isUseful": "Was the article useful to you?"
            }
        }
    },
    chinese: {
        translation: {
            home: {
                "title": "RELIABLE TRUSTED REMITTANCE WE HELP SAVE COST",
                "businessBtn": "For Businesses",
                "individualBtn": "For Individuals",
                "findUs": "You can find us here",
                "contactUs": "contact us"
            },
            sendTo: {
                "title": "Where we send to",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse praesent at tellus sed vitae. Est semper elementum, vitae vel lectus. Arcu velit lobortis metus, tempor, elementum eu. Nulla senectus eget nisi, arcu malesuada.",
                "searchPlc": "Search country",
                "searchBtn": "Search",
            },
            wizdraw: {
                "title": "Wizdraw Mobile APP",
                "title1": "With WIZDRAW App",
                "description1": "Our mission is to empower migrant workers and their families to become financially literate and in control using innovative, app-based technologies",
                "title2": "WIZDRAW App",
                "description2": "Revolutionary version give senders the ability to send foreign currency and the beneficiaries the ability to shop the best rate locally or choose the best remittance location based on their selected criteria.",
                "title3": "WIZDRAW App",
                "description3": "Plan involves the creation of a number of in-app features that will use machine learning to educate users and encourage good financial decisions.",
                "downloadBtn": "Download now, and join our happy family"
            },
            localPayment: {
                "title": "at AT Services, we work for you!",
                "subTitle": "at AT Services, we work for you!",
                "description": "With ATS, you will benefit from saving your time and resources to select the right payment solutions to fit your business needs. We have a service platform that can integrate easily with any local payment's provider. Collect payments more efficiently with ATS."
            },
            network: {
                "title": "the network",
                "subTitle": "We never stop building our worldwide payments network",
                "description": "ATS acting for you, the merchant, to reach any potential customer worldwide, and you will be able to let your potential customers pay in any way they want and get paid according to their choice anywhere."
            },
            solutions: {
                "title": "our solutions",
                "subTitle": "our solutions",
                "description": [`We specialise in enterprise level payment technology that is flexible enough for businesses of all shapes and sizes, 
                                to accept and process payments.`, `Our software 
                                suits is tailored for large array of clients 
                                - from eCommerce online stores through 
                                to subscription businesses, platforms 
                                marketplaces, exporters, importers which 
                                make salary payments, pensions, bonuses, 
                                invoices settlements and etc.`, `We offer an end - to - 
                                end software solution for all your payment needs with 
                                multiple channels to choose from.`],
                "securityTitle": "Security",
                "securityDescription": "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care",
                "paymentMethodsTitle": "Accepted Payment Methods",
                "paymentMethodsDescription": "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network...",
                "compatibilityTitle": "Compatibility",
                "compatibilityDescription": "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements.",
                "paymentLocationTitle": "Payment Location",
                "paymentLocationDescription": "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers.",
                "costTitle": "Cost",
                "costDescription": "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay.",
                "readyTo": "want to",
                "join": "join",
                "joinSubTitle": "join us, and leave the worrying to us"
            },
            complianceAndRisk: {
                "title": "compliance & \n risk management",
                "description": "The Company’s risk management processes and technology are a central part of its business. The Company uses a risk management system that allows it to analyse a range of data to prevent and detect compliance issues and fraud, including pull-API, transaction matching, and account reconciliation, external third-party auditing, and sending limits. The Company’s risk management technology helps to manage the primary risk challenges that confront its business.",
                "titleMore": "company compliance and risk management innovation",
                "descriptionMore": "Blockchain Compliance System. The Company has created an innovative new compliance system, which is intended to be linked via API connection to the Transaction Management System, using advanced blockchain technology. The Company refers to this system as its “blockchain compliance system”. It is based on the Hyperledger Fabric protocol. The project aims to bring together a number of independent efforts to develop open protocols and standards, by providing a modular framework that supports different components for different uses, including blockchains with their own consensus and storage models, and services for identity, access control, and contracts.  The Company’s blockchain compliance system tracks the amount of each customer’s remittance, the date, time and location of the remittance, and all KYC documentation related to the customer and the beneficiary, and provide relevant information, on a permissioned basis, to the Company’s remittance network, including depository and correspondent banks, as well as governmental authorities.",
                "titlePrinciples": "our anti money laundry principles",
                "p1": "Payment and Fraud Risk.",
                "p3": "Collection of each user’s valid national ID, proof of address and personal photo.",
                "p5": "A limitation on the maximum amount permitted to be remitted by any single customer per month.",
                "p2": "Anti-Money Laundering and Regulatory Compliance.",
                "p4": "Screening of each customer’s name against international terrorist, criminal and other lists.",
                "p6": "Quarterly compulsory reporting to relevant regulators. On-going AML training for personnel.",
            },
            careers: {
                "title": "ATS Create Global Opportunities",
                "description": "We are on a mission to deliver Migrant workers money  to their family back home fast, secure, and easy. ATS is a great place to work with a family culture.  With Activty in Hong Kong, Singapore, Philippines and UK, you will enjoy an international envirment. ATS has a strong team and employee culture.  You’ll work with inspiring people and be an essential part of the team.",
                "subTitle": "In ATS we Believe In People"
            },
            contactUs: {
                "title": "contact us",
                "titleGreen": "Help?",
                "subtitle": "Send us a message and tell us how we can help, and how we can reach you."
            },
            faq: {
                "title": "How can we",
                "titleGreen": "help you?"
            },
            aboutUs: {
                "title": "about us",
                "titleWhyChoose": "why choose us",
                "subTitle": "AT Services Ltd is a company incorporated in Hong Kong in 2015",
                "description": "AT Services provides you with knowledge and expertise, professionalism and ability that is only gained at the highest levels in the foreign exchange markets. Our mission is to provide outstanding exchange rates, the fastest and most secure overseas payment services, efficient and friendly customer service that people will want to recommend to others AT Services offers a personal and informed foreign currency exchange facility. We replace the traditional role of the bank in the money exchange transaction, and in doing so, offer a higher level of currency analysis and service not normally found with the banks. We will save you money through commission free transactions and better exchange rates. AT Services has clients in 53 countries and last year made payments into 95 jurisdictions. We have that global reach and experience that our clients rely on to enhance their own business. We are your global foreign exchange and payments partner.",
                "descriptionWhyChoose": "We are proud of our cross-cultural leadership, and it helps us better understand the needs of our customers from all over the world. The second thing is that we are a team of payment and technology professionals with more than 50 years of experience in the banking and financial services sector. We use the most advantageous systems that technology has to offer to protect you. We are regulated wherever we are located, and our partners are only Banks and regulated financial institutes.",
                "titleMissions": "our mission",
                "mission1": "To offer the best currency exchange rates and lowest charges.",
                "mission2": "To provide fast, safe, secure, convenient, and reliable means of making payments and money transfer.",
                "mission3": "To provide highly transparent, affordable well-detailed remittance and currency exchange services.",
                "mission4": "To offer zero tolerance to fraud and money laundering.",
                "titlePayers": "Our Payers",
            },
            common: {
                "learnMoreBtn": "Learn More",
                "copyRight": { title: "© 2021 COPYRIGHT", privacy: "Privacy Policy", cookies: "Cookies Policy", terms: "Terms & Conditions" },
                "emailPlc": "Enter your mail",
                "namePlc": "Full Name",
                "emailPlcForm": "Email",
                "phonePlc": "Phone No. (optional)",
                "subjectPlc": "Subject",
                "messagePlc": "Message",
                "selectPlc": "Please Choose",
                "submit": "Submit",
                "isUseful": "Was the article useful to you?"
            }
        }
    },
    france: {
        translation: {
            home: {
                "title": "RELIABLE TRUSTED REMITTANCE WE HELP SAVE COST",
                "businessBtn": "pour le business",
                "individualBtn": "For Individuals",
                "findUs": "You can find us here",
                "contactUs": "contact us"
            },
            sendTo: {
                "title": "Where we send to",
                "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse praesent at tellus sed vitae. Est semper elementum, vitae vel lectus. Arcu velit lobortis metus, tempor, elementum eu. Nulla senectus eget nisi, arcu malesuada.",
                "searchPlc": "Search country",
                "searchBtn": "Search",
            },
            wizdraw: {
                "title": "Wizdraw Mobile APP",
                "title1": "With WIZDRAW App",
                "description1": "Our mission is to empower migrant workers and their families to become financially literate and in control using innovative, app-based technologies",
                "title2": "WIZDRAW App",
                "description2": "Revolutionary version give senders the ability to send foreign currency and the beneficiaries the ability to shop the best rate locally or choose the best remittance location based on their selected criteria.",
                "title3": "WIZDRAW App",
                "description3": "Plan involves the creation of a number of in-app features that will use machine learning to educate users and encourage good financial decisions.",
                "downloadBtn": "Download now, and join our happy family"
            },
            localPayment: {
                "title": "at AT Services, we work for you!",
                "subTitle": "at AT Services, we work for you!",
                "description": "With ATS, you will benefit from saving your time and resources to select the right payment solutions to fit your business needs. We have a service platform that can integrate easily with any local payment's provider. Collect payments more efficiently with ATS."
            },
            network: {
                "title": "the network",
                "subTitle": "We never stop building our worldwide payments network",
                "description": "ATS acting for you, the merchant, to reach any potential customer worldwide, and you will be able to let your potential customers pay in any way they want and get paid according to their choice anywhere.",
                "emailTitleGreen": "partner with us?",
            },
            solutions: {
                "title": "our solutions",
                "subTitle": "our solutions",
                "description": [`We specialise in enterprise level payment technology that is flexible enough for businesses of all shapes and sizes, 
                                to accept and process payments.`, `Our software 
                                suits is tailored for large array of clients 
                                - from eCommerce online stores through 
                                to subscription businesses, platforms 
                                marketplaces, exporters, importers which 
                                make salary payments, pensions, bonuses, 
                                invoices settlements and etc.`, `We offer an end - to - 
                                end software solution for all your payment needs with 
                                multiple channels to choose from.`],
                "securityTitle": "Security",
                "securityDescription": "ATS will provide you a payment method that’s reputable and offers safe checkout experiences for your customers. ATS will handle your customers’ delicate data with care",
                "paymentMethodsTitle": "Accepted Payment Methods",
                "paymentMethodsDescription": "By outfitting your site with our payment methods that incorporate a wide variety of payment methods, you’re widening your sales network...",
                "compatibilityTitle": "Compatibility",
                "compatibilityDescription": "We will customize to match your site’s feel to ensure your payment tool is compatible with your brand. Don’t make your customers’ purchasing experiences jarring with mismatched elements.",
                "paymentLocationTitle": "Payment Location",
                "paymentLocationDescription": "We will provide you an API that incorporates directly into your site. ATS will provide the best user experience for your shoppers.",
                "costTitle": "Cost",
                "costDescription": "No hidden fees No setup or monthly fee We offer fair prices for all sizes of businesses. You always know what you pay.",
                "readyTo": "want to",
                "join": "join",
                "joinSubTitle": "join us, and leave the worrying to us"
            },
            complianceAndRisk: {
                "title": "compliance & \n risk management",
                "description": "The Company’s risk management processes and technology are a central part of its business. The Company uses a risk management system that allows it to analyse a range of data to prevent and detect compliance issues and fraud, including pull-API, transaction matching, and account reconciliation, external third-party auditing, and sending limits. The Company’s risk management technology helps to manage the primary risk challenges that confront its business.",
                "titleMore": "company compliance and risk management innovation",
                "descriptionMore": "Blockchain Compliance System. The Company has created an innovative new compliance system, which is intended to be linked via API connection to the Transaction Management System, using advanced blockchain technology. The Company refers to this system as its “blockchain compliance system”. It is based on the Hyperledger Fabric protocol. The project aims to bring together a number of independent efforts to develop open protocols and standards, by providing a modular framework that supports different components for different uses, including blockchains with their own consensus and storage models, and services for identity, access control, and contracts.  The Company’s blockchain compliance system tracks the amount of each customer’s remittance, the date, time and location of the remittance, and all KYC documentation related to the customer and the beneficiary, and provide relevant information, on a permissioned basis, to the Company’s remittance network, including depository and correspondent banks, as well as governmental authorities.",
                "titlePrinciples": "our anti money laundry principles",
                "p1": "Payment and Fraud Risk.",
                "p3": "Collection of each user’s valid national ID, proof of address and personal photo.",
                "p5": "A limitation on the maximum amount permitted to be remitted by any single customer per month.",
                "p2": "Anti-Money Laundering and Regulatory Compliance.",
                "p4": "Screening of each customer’s name against international terrorist, criminal and other lists.",
                "p6": "Quarterly compulsory reporting to relevant regulators. On-going AML training for personnel.",
            },
            careers: {
                "title": "ATS Create Global Opportunities",
                "description": "We are on a mission to deliver Migrant workers money  to their family back home fast, secure, and easy. ATS is a great place to work with a family culture.  With Activty in Hong Kong, Singapore, Philippines and UK, you will enjoy an international envirment. ATS has a strong team and employee culture.  You’ll work with inspiring people and be an essential part of the team.",
                "subTitle": "In ATS we Believe In People"
            },
            contactUs: {
                "title": "contact us",
                "titleGreen": "Help?",
                "subtitle": "Send us a message and tell us how we can help, and how we can reach you."
            },
            faq: {
                "title": "How can we",
                "titleGreen": "help you?"
            },
            aboutUs: {
                "title": "about us",
                "titleWhyChoose": "why choose us",
                "subTitle": "AT Services Ltd is a company incorporated in Hong Kong in 2015",
                "description": "AT Services provides you with knowledge and expertise, professionalism and ability that is only gained at the highest levels in the foreign exchange markets. Our mission is to provide outstanding exchange rates, the fastest and most secure overseas payment services, efficient and friendly customer service that people will want to recommend to others AT Services offers a personal and informed foreign currency exchange facility. We replace the traditional role of the bank in the money exchange transaction, and in doing so, offer a higher level of currency analysis and service not normally found with the banks. We will save you money through commission free transactions and better exchange rates. AT Services has clients in 53 countries and last year made payments into 95 jurisdictions. We have that global reach and experience that our clients rely on to enhance their own business. We are your global foreign exchange and payments partner.",
                "descriptionWhyChoose": "We are proud of our cross-cultural leadership, and it helps us better understand the needs of our customers from all over the world. The second thing is that we are a team of payment and technology professionals with more than 50 years of experience in the banking and financial services sector. We use the most advantageous systems that technology has to offer to protect you. We are regulated wherever we are located, and our partners are only Banks and regulated financial institutes.",
                "titleMissions": "our mission",
                "mission1": "To offer the best currency exchange rates and lowest charges.",
                "mission2": "To provide fast, safe, secure, convenient, and reliable means of making payments and money transfer.",
                "mission3": "To provide highly transparent, affordable well-detailed remittance and currency exchange services.",
                "mission4": "To offer zero tolerance to fraud and money laundering.",
                "titlePayers": "Our Payers",
            },
            common: {
                "learnMoreBtn": "Learn More",
                "copyRight": { title: "© 2021 COPYRIGHT", privacy: "Privacy Policy", cookies: "Cookies Policy", terms: "Terms & Conditions" },
                "emailPlc": "Enter your mail",
                "namePlc": "Full Name",
                "emailPlcForm": "Email",
                "phonePlc": "Phone No. (optional)",
                "subjectPlc": "Subject",
                "messagePlc": "Message",
                "selectPlc": "Please Choose",
                "submit": "Submit",
                "join": "join",
                "isUseful": "Was the article useful to you?",
            }
        }
    }
}