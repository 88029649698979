import React, { useEffect } from "react";

const withOutsideClick = Component => props => {
    const { componentRef, onClose } = props;
    
    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => document.removeEventListener("click", handleClick);

        function handleClick(e) {
            if (componentRef && componentRef.current) {
                if (!componentRef.current.contains(e.target)) {
                    onClose();
                }
            }
        }
    }, [componentRef, onClose]);

    return (
        <Component {...props} />
    )
};

export default withOutsideClick;