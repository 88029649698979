import React from "react";
import styles from "./Text.module.scss";
import { oneOf, bool ,string } from "prop-types";

const Text = ({ text, color, background, underline, bold, size, align, classNames }) => {
    const TextClassNames = `${styles["text"]} 
        ${styles[color]} 
        ${styles[size]} 
        ${styles[background]}
        ${styles[align]}
        ${classNames}
        ${underline ? styles["underline"] : ""} 
        ${bold ? styles["bold"] : ""}`;
    return <p className={`${TextClassNames}`}>{text}</p>;
};

const colors = ["blue", "black", "green"];
const backgroundColors = [
    "backgroundGreen",
    "backgroundBlue",
    "backgroundBlack",
    "backgroundTransparent",
];
const sizes = ["xl", "lg", "md", "sm", "xs"];
const alignings = ["center" , "left", "right","none"];

Text.defaultProps = {
    text: "",
    color: colors[1],
    background: backgroundColors[3],
    sizes: sizes[2],
    underline: false,
    bold: false,
    align : "none"
};

Text.propTypes = {
    color: oneOf(colors),
    background: oneOf(backgroundColors),
    size: oneOf(sizes),
    align : oneOf(alignings),
    underline: bool,
    bold: bool,
    text: string
};

export default Text;
