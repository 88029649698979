import React, { useState } from "react";
import { emailValidation } from "../../helpers/Validations";
import joinUsService from "../../services/joinUsService";
import "../../assets/styles/inputWithBtn.css";

const EmailInput = ({ buttonName }) => {
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState();
    const [value, setValue] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const defaultPlaceholder = "Enter your mail";
    const successPlaceholder = "Done! We will send you an email shortly!";
    const dangerPlaceholder =
        "*Email address seems invalid! Please enter the correct one.";

    const onInputChange = (e) => {
        const valueInner = e.target.value;

        if (valueInner.length > 0) {
            e.target.placeholder = "";
        } else {
            e.target.placeholder = defaultPlaceholder;
        }
        if (!(/\s/.test(valueInner))){
            setValue(valueInner);
            setLoading(false);
            setIsButtonDisabled(false);
            setIsValid();
        if(valueInner === ""){
            setValue(valueInner)
            setIsButtonDisabled(true)
        }
        }else{
            setIsButtonDisabled(true)
        }
        
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const email = value.trim();
        if (email) {
            setLoading(true);
            if (emailValidation(email)) {
                setTimeout(async () => {
                    try {
                        await joinUsService({ email: value });
                        setIsValid(true);
                        setValue("");
                        setLoading(false);
                    } catch (err) {
                        setIsValid(false);
                        setLoading(false);
                        setValue("");
                        isButtonDisabled(true);
                    }
                }, 2000);
                return true;
            }

            setTimeout(() => {
                setLoading(false);
                setIsValid(false);
                setIsButtonDisabled(true);
                setValue("");
            }, 300);
            return false;
        }
    };

    const button = (
        <input
            className={`input__withBtn__btn ${isValid ? "valid" : ""} ${
                isButtonDisabled ? "disabled" : ""
            } btn-hover`}
            type={"submit"}
            disabled={isValid}
            value={buttonName}
        />
    );

    const loadingIcon = <div className="lds-dual-ring"></div>;
    let inputPlaceholder = defaultPlaceholder;

    if (isValid === true) {
        inputPlaceholder = successPlaceholder;
    } else if (isValid === false) {
        inputPlaceholder = dangerPlaceholder;
    }

    return (
        <form className="input__withBtn__box" onSubmit={onSubmitHandler}>
            <input
                type="text"
                className={`input__withBtn ${
                    isValid === false ? "danger" : ""
                } ${isValid === true ? "success" : ""}`}
                placeholder={inputPlaceholder}
                value={value}
                onChange={(e) => onInputChange(e)}
            />
            {loading ? loading : button}
            {loading ? loadingIcon : null}
        </form>
    );
};

export default EmailInput;
