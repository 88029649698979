import React from "react";

import "../../assets/styles/card.css";

const Card = ({ card }) => {
    return (
        <div className="card">
            <img className="card__icon" src={`../images/${card.name}_card.svg`} alt={card.name}/>
            <p className="card__title">{card.title}</p>
            <p className="card__description">{card.description}</p>
        </div>
    )
};

export default Card;