import PropTypes from 'prop-types';
import styles from './index.module.scss';

const STYLES = [
    'primary--solid',
    'secondary--solid',
    'primary--outline',
    'secondary--outline',
    'primary--circle',
    'secondary--circle',
    'close',
];
const POSITIONS = [
    "center"
]

const SIZES = [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'auto'
];

const AButton = ({
        absolute,
        children,
        variant="primary--solid",
        size="md",
        circleText = "",
        position = "",
        onClick = ()=>{},
        ...rest
    }) => {
    return (
        <div className={`${styles.container} ${absolute ? styles.absolute : null } ${styles[position]}`}>
            <button className={`${styles.btn} ${styles[variant]} ${styles[size]}`} onClick={onClick} {...rest}>
                { children }
            </button>
            <p>
                {circleText}
            </p>
        </div>
        
    );
};

AButton.propTypes = {
    variant: PropTypes.oneOf(STYLES),
    size: PropTypes.oneOf(SIZES),
    position : PropTypes.oneOf(POSITIONS)
};

export default AButton;
