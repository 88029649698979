import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PdfDocuments, PRIVACY_POLICY } from "../../constants/pdfs";
import pdf from "../../assets/pdf/PrivacyPolicy.pdf";
import "../../assets/styles/PdfView.css";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfView = ({ isOpen, setIsOpen, pdfName }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const parentClassName = "pdf_parent_container";
    const onCloseHandler = (e) => {
        if (e.target.className !== parentClassName) {
            return;
        }
        setPageNumber(1);
        setIsOpen(false);
    };

    const wrapperRef = useRef();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const onButtonRightClick = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        } else {
            setPageNumber(1);
        }
    };

    const onButtonLeftClick = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        } else {
            setPageNumber(numPages);
        }
    };
    
    const Component = isOpen ? (
        <div className={parentClassName} onClick={(e) => onCloseHandler(e)}>
            <div className="pdf_container" ref={wrapperRef}>
                <Document
                    file={PdfDocuments[pdfName]}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </div>
            <div className="pdf_footer">
                <p>
                    Page {pageNumber} of {numPages}
                </p>
                <div className="pdf_btn_container">
                    <button onClick={onButtonLeftClick}>{"<"}</button>
                    <button onClick={onButtonRightClick}>{">"}</button>
                </div>
            </div>
        </div>
    ) : null;
    return Component;
};

export default PdfView;
