import React from "react";


import ContentBox from "../../common/ContentBox";
import InputCard from "../../common/InputCard/InputCard";
import {ViewRightLeft, ViewImage} from "../../View";
import EmailInput from "../../common/EmailInput";
import { useTranslation } from "react-i18next";
import Banner from "../../../assets/media/localPayment_banner.svg"


const LocalPayment = () => {
    const { t } = useTranslation();

    const data = [
        {
            description:
               [`A consultant that knows your real business needs about local card payment acceptance`,
               `With ATS expertise and merchant card acceptance experiences, ATS is working with 
               respective local payment partners to provide trustworthy bespoke payment solutions. 
               You will benefit from saving your time and resources to select the right payment solutions 
               that fit your business needs!`],
        },
    ];

    return (
        <>
            <ViewRightLeft>
                <ContentBox
                    title={t("localPayment.title")}
                    // subtitle={t("localPayment.subTitle")}
                    description={t("localPayment.description",{returnObjects : true})}
                    data={data}
                />
                <ViewImage image={Banner}></ViewImage>
            </ViewRightLeft>
            <div>
                <InputCard
                    title={"want to"}
                    titleGreen={"partner with us?"}
                    subtitle={"join us, and leave the rest to us"}
                    type={"blank"}
                >
                    <EmailInput placeholder={t('common.emailPlc')} buttonName={t('solutions.join')} icon={true}/>
                </InputCard>
            </div>
        </>
    );
};

export default LocalPayment;
