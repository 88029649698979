import { useTranslation } from "react-i18next";
import React from "react";

import "./Careers.css";

import ContentBox from "../../common/ContentBox";
import Accordion from "../../common/Accordion";

const Careers = () => {
    const { t } = useTranslation();

    const careerData = [
        {
            id: 1,
            title: "CEO Assistant",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien, phasellus elit enim purus fringilla sit consectetur massa ornare. Vulputate proin ac tincidunt amet accumsan aenean eleifend. Justo laoreet condimentum sagittis urna elementum non vitae. Imperdiet purus, libero enim, duis metus, sit sapien, nisl. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien, phasellus elit enim purus fringilla sit consectetur massa ornare. Vulputate proin ac tincidunt amet accumsan aenean eleifend. Justo laoreet condimentum sagittis urna elementum non vitae. Imperdiet purus, libero enim, duis metus, sit sapien, nisl."
        },
        {
            id: 2,
            title: "CEO Assistant",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien, phasellus elit enim purus fringilla sit consectetur massa ornare"
        },
        {
            id: 3,
            title: "CEO Assistant",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sapien, phasellus elit enim purus fringilla sit consectetur massa ornare"
        }
    ]

    return (
        <section className="career">
            <div className="career__content">
                <ContentBox title={t('careers.title')} description={t('careers.description')}/>
            </div>
            <h4 className="career__subtitle">{t('careers.subTitle')}</h4>
            <Accordion data={careerData} />
        </section>
    )
};

export default Careers;