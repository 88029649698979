
import PrivacyPolicy from "../assets/pdf/PrivacyPolicy.pdf";
import CookiesPolicy from "../assets/pdf/CookiesPolicy.pdf";
import TermsConditions from "../assets/pdf/TermsConditions.pdf";

export const PRIVACY_POLICY = "privacy";
export const COOKIES_POLICY = "cookies";
export const TERMS_CONDITIONS = "terms";

export const PdfDocuments = {
  [PRIVACY_POLICY]: PrivacyPolicy,
  [COOKIES_POLICY]: CookiesPolicy,
  [TERMS_CONDITIONS]: TermsConditions,
}