import { useTranslation } from "react-i18next";
import React, { useState } from "react";

const LearnMore = ({ data }) => {
    const { t } = useTranslation();

    const [opened, setOpened] = useState(false);

    return (
        <>
            {opened ? (
                data.map((info, index) => (
                    <div className="contentBox__row" key={index}>
                        <p className="contentBox__subtitle">{info.subTitle}</p>
                        {typeof info.description === "object" ? (
                            info.description.map((val, index) => (
                                <p
                                    className="contentBox__description"
                                    key={Math.random() * index}
                                >
                                    {val}
                                </p>
                            ))
                        ) : (
                            <p className="contentBox__description">
                                {info.description}
                            </p>
                        )}
                    </div>
                ))
            ) : (
                <button
                    className="contentBox__learnMoreBtn btn-hover"
                    onClick={() => setOpened(true)}
                >
                    {t("common.learnMoreBtn")}
                </button>
            )}
        </>
    );
};

export default LearnMore;
