import React from "react";
import { MapContext } from "../../../../App";
import styles from "./index.module.scss";

const AddressField = ({ data }) => {
    return (
        <div
            className={`${styles["address_container"]} accordion__text__container`}
        >
            <div className={`${styles["address_item__container"]}`}>
                <p className={`${styles["name"]}`}>{data.title}</p>
                <div className={`${styles["map__container"]}`}>
                    <p className={`${styles["address"]}`}>{data.address}</p>
                    <MapContext.Consumer>
                        {(map) => (
                            <p
                                className={`${styles["map"]}`}
                                onClick={() => {
                                    map.changeMapCoordinates((prev) => {
                                        return { ...data.coordinates };
                                    });
                                    map.openMap(true);
                                }}
                            >
                                Map
                            </p>
                        )}
                    </MapContext.Consumer>
                </div>
            </div>

        </div>
    );
};

export default AddressField;
