import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import "./Faq.css";

import Selectbox from "../../common/Selectbox";
import InputCard from "../../common/InputCard/InputCard";


const INITIAL_DATA = [
    {
        id: 1,
        title: "How to be a Partner?",
        date: "2 months ago",
        answer: `borem ipsum dolor sit amet, consectetur
                 adipiscing elit. Sem feugiat tristique posuere et,
                 enim faucibus. Integer mus lacinia viverra dignissim
                 blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas. Lorem ipsum
                 dolor sit amet, consectetur adipiscing elit. Sem feugiat
                 tristique posuere et, enim faucibus. Integer mus lacinia viverra
                 dignissim blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas.`,
        liked : null,
    }, {
        id: 2,
        title: "API Support",
        date: "7 months ago",
        answer: `sorem ipsum dolor sit amet, consectetur
                 adipiscing elit. Sem feugiat tristique posuere et,
                 enim faucibus. Integer mus lacinia viverra dignissim
                 blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas. Lorem ipsum
                 dolor sit amet, consectetur adipiscing elit. Sem feugiat
                 tristique posuere et, enim faucibus. Integer mus lacinia viverra
                 dignissim blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas.`,
        liked : null,
    }, {
        id: 3,
        title: "How to be Affiliate",
        date: "5 month ago",
        answer: `xorem ipsum dolor sit amet, consectetur
                 adipiscing elit. Sem feugiat tristique posuere et,
                 enim faucibus. Integer mus lacinia viverra dignissim
                 blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas. Lorem ipsum
                 dolor sit amet, consectetur adipiscing elit. Sem feugiat
                 tristique posuere et, enim faucibus. Integer mus lacinia viverra
                 dignissim blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas.`,
        liked : null,

    }, {
        id: 4,
        title: "compliance & legal",
        date: "3 months ago",
        answer: `xorem ipsum dolor sit amet, consectetur
                 adipiscing elit. Sem feugiat tristique posuere et,
                 enim faucibus. Integer mus lacinia viverra dignissim
                 blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas. Lorem ipsum
                 dolor sit amet, consectetur adipiscing elit. Sem feugiat
                 tristique posuere et, enim faucibus. Integer mus lacinia viverra
                 dignissim blandit nulla. Volutpat adipiscing leo gravida odio.
                 Eleifend massa massa a, amet maecenas.`,
        liked : null,
    }
];

const Faq = () => {

    const { t } = useTranslation();
    const [data, setData] = useState(INITIAL_DATA);
    const [itemId, setItemId] = useState();


    const dataFilter = (value) => {
        const sda = data.map((x) => {
            if(x.id === itemId) {
                if (x.liked === null) {
                    const asd = {
                        ...x,
                        liked: value
                    };
                    return asd;
                } else if ((x.liked && value) || (!x.liked && !value)) {
                    const asd = {
                        ...x,
                        liked: null
                    };
                    return asd;
                } else if((x.liked && !value) || (!x.liked && value)) {
                    const asd = {
                        ...x,
                        liked: value
                    };
                    return asd;
                }
            }
            return x;
        })
        setData([...sda]);
    }

    const onButtonClick = (value) => {
        dataFilter(value);
    }

    const found = data.find(x => x.id === itemId);
    const btnUpOpacity = found?.liked === true  ? '1' : '0.6';
    const btnDownOpacity = found?.liked === false ? '1' : '0.6';

    return (
        <section className="faq">
            <InputCard type="select-box"
                       title={t("faq.title")}
                       titleGreen={t("faq.titleGreen")}
                       >
                <Selectbox data={data} onSelect={setItemId} selected={itemId}></Selectbox>
            </InputCard>

            {found && <div className="faq__answerBox">
                <h2 className="title-big faq__answerBox__title">{found.title}</h2>
                <h4 className="faq__answerBox__date">{found.date}</h4>
                <p className="faq__answerBox__answer">{found.answer}</p>
                <div className="faq__answerBox__footer">
                    <span className="faq__answerBox__is-useful">{t('common.isUseful')}</span>
                    <button onClick={()=>onButtonClick(true)} style={{opacity:btnUpOpacity}} className="faq__thumb faq__thumb_up" />
                    <button onClick={()=>onButtonClick(false)} style={{opacity:btnDownOpacity}} className="faq__thumb faq__thumb_down"/>
                </div>
            </div>}
        </section>
    )
};

export default Faq;
