import { useTranslation } from "react-i18next";
import React from "react";
import openDownloadPage from "../../../helpers/OpenDownloadLink";
import { ViewRightLeft, ViewImage } from "../../View";
import bannerImage from "../../../assets/media/wizdraw_banner.png";

import "./Wizdraw.css";
import ContentBox from "../../common/ContentBox";

const Wizdraw = () => {
    const { t } = useTranslation();

    const onButtonClickHandler = () => {
        openDownloadPage();
    };

    return (
        <section className="wizdraw">
            <ViewRightLeft>
                <ContentBox
                    title={t("wizdraw.subtitle")}
                    description={t("wizdraw.description", {
                        returnObjects: true,
                    })}
                ></ContentBox>
                <ViewImage image={bannerImage}></ViewImage>
            </ViewRightLeft>
            {/* <div className="wizdraw__bg">
                <div className="wizdraw__content">
                    <Text text={t("wizdraw.title")} size="xl" bold={true} color={"blue"} >
                    </Text>
                    <Text text={"The easiest way to send money \n24/7"} bold={true} size="lg"></Text>
                    <ul>
                        <WizdrawInfo
                            title={t("wizdraw.title1")}
                            text={t("wizdraw.description1")}
                        />
                        <WizdrawInfo
                            title={t("wizdraw.title2")}
                            text={t("wizdraw.description2")}
                        />
                        <WizdrawInfo
                            title={t("wizdraw.title3")}
                            text={t("wizdraw.description3")}
                        />
                    </ul>
                </div>
                <div className="wizdraw__bg__img"></div>
            </div> */}
            <div className="wizdraw__download">
                <div
                    target="_blank"
                    rel="noreferrer"
                    className="wizdraw__googlePlay"
                ></div>

                <div
                    target="_blank"
                    rel="noreferrer"
                    className="wizdraw__appStore"
                ></div>
                <div />
                <button
                    className="wizdraw__downloadBtn btn-hover"
                    onClick={onButtonClickHandler}
                >
                    {t("wizdraw.downloadBtn")}
                </button>
            </div>
        </section>
    );
};

export default Wizdraw;
