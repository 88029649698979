import React from "react";
import styles from "./ErrorPage.module.scss";
import airplaneBlue from "../../assets/media/airplaneBlue.svg";
import airplaneWhite from "../../assets/media/airplaneWhite.svg";
import cloud from "../../assets/media/icons/cloud.svg";
import character from "../../assets/media/manWithBinocular.svg";

const ErrorPage = () => {
    return (
        <div className={`${styles.container} ${styles.flexCentered}`}>
            <div className={`${styles.content} ${styles.flexCentered}`}>
                <img
                    src={cloud}
                    alt="cloud icon"
                    className={`${styles.cloud} ${styles.left}`}
                ></img>
                <img
                    src={cloud}
                    alt="cloud icon"
                    className={`${styles.cloud} ${styles.right}`}
                ></img>
                <img
                    src={airplaneBlue}
                    alt="airplane origami"
                    className={`${styles.plane} ${styles.left}`}
                ></img>
                <img
                    src={airplaneWhite}
                    alt="airplane origami"
                    className={`${styles.plane} ${styles.right}`}
                ></img>
                <img
                    src={airplaneBlue}
                    alt="airplane origami"
                    className={`${styles.plane} ${styles.middle}`}
                ></img>
                <div
                    className={`${styles.errorMessage} ${styles.flexCentered}`}
                >
                    <h1>404</h1>
                    <p>Page not found</p>
                </div>
                <img className={styles.character} src={character} alt="character icon"></img>
            </div>
        </div>
    );
};

export default ErrorPage;
