import twitter from '../assets/media/icons/twitter.svg';
import linkedin from '../assets/media/icons/linkedin.svg';
import facebook from '../assets/media/icons/facebook.svg';

export const socialPlatforms = [
    {
        name: "facebook",
        url: "https://www.facebook.com/wizdrawremit/",
        icon: facebook,
    },
    {
        name: "linkedin",
        url: "https://hk.linkedin.com/company/wizdraw",
        icon: linkedin
    },
    {
        name: "twitter",
        url: "https://twitter.com/wizdrawapp",
        icon: twitter,
    },
]

export const contactMail = "info@atstopco.com";
export const contactNumber = "+639 556 343 200";
export const phoneNumber = "(852) 3678 6741";
export const googlePlay = "https://play.google.com/store/apps/details?id=com.ionicframework.wicapp652054";
export const appStore = "https://www.apple.com/am/app-store";