import React from "react";
// import AButton from "../common/AButton";
import SliderInfo from "../common/SliderInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Pagination,
    Autoplay
  } from 'swiper';

import { useHistory } from "react-router";
import FirstImage from '../../assets/media/individual_first.png';
// import TriangleRight from '../../assets/media/icons/video_button.svg'
import SecondImage from '../../assets/media/individual_second.png';
import 'swiper/swiper-bundle.min.css';
SwiperCore.use([Pagination, Autoplay]);

// const url = "https://play.google.com/store/apps/details?id=com.ionicframework.wicapp652054";
//window.open(url,"_blank") || window.location.replace(url)
const Individuals = () => {

    const history = useHistory();

    const offersList = {
        title_first : '',
        title_second : 'Using our mobile app costs you',
        first: [ 'The right way to send money home.', 'We charge as little as possible.' ],
        second: [ '0HKD to Sign Up', '0HKD Monthly Fee' ],
    };
    const pagination = {
        "clickable": true,
    };

    return (
        <>
        <div>
        <Swiper
            pagination={pagination}
            allowTouchMove={false}
            loop={true}
            // autoplay={{
            //     "delay": 3500,
            //     "disableOnInteraction": false,
            //   }}
        >
            <SwiperSlide>
                <SliderInfo
                    header={'our global transfer network for your service'}
                    offersList={offersList.first}
                    btn={{
                        function: () => history.push('/individuals/wizdraw'),
                        text: 'Start sending money',
                    }}
                    img={FirstImage}
                />
            </SwiperSlide>
            <SwiperSlide>
                <SliderInfo
                    offersListTitle={offersList.title_second}
                    header='no hidden fee & not small letters'
                    offersList={offersList.second}
                    bottomInfo="When sending money with us we charge the lowest fees and offer the best rates."
                    btn={{
                        function: () => history.push('/individuals/wizdraw'),
                        text: 'Check our prices',
                    }}
                    img={SecondImage}
                />
            </SwiperSlide>
        </Swiper>
        {/* button with video icon, might be used */}
        {/* <AButton
                absolute
                variant="secondary--circle"
                circleText="See how it’s working."
            >
                <img src={TriangleRight} style={{ marginLeft: 5 }} alt="TriangleRight" />
        </AButton> */}
        </div>
        </>
    )
};

export default Individuals;