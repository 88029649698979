import React from "react";
import styles from "./AButtonSandwich.module.scss";

const AButtonSandwich = ({ onClick, isOpen }) => {
    
    const onClickHandler = () => {
        onClick(!isOpen);
    };

    return (
        <div
            className={`${styles["container"]} ${
                isOpen ? styles["opened"] : ""
            }`}
            onClick={onClickHandler}
        >
            <div className={styles["col"]}></div>
            <div className={styles["col-close__container"]}>
                <div
                    className={`${styles["col-close"]} ${styles["close-1"]}`}
                ></div>
                <div
                    className={`${styles["col-close"]} ${styles["close-2"]}`}
                ></div>
            </div>
            <div className={styles["col"]}></div>
        </div>
    );
};

export default AButtonSandwich;
