import { withRouter } from "react-router";
import React from "react";

import HomeFooter from "./HomeFooter";
import AppFooter from "./AppFooter";

const Footer = ({ location: { pathname }, setPopupOpen,onChangePdfName } ) => {
    return (
        <>
            {pathname === "/" ? (
                <HomeFooter />
            ) : (
                <AppFooter setPopupOpenTrue={setPopupOpen} onChangePdfName={onChangePdfName} />
            )}
        </>
    );
};

export default withRouter(Footer);
