import { Link } from "react-router-dom";
import React from "react";

import withOutsideClick from "../../helpers/OutsideClick";
import DropdownSub from "./DropdownSub";

const Dropdown = withOutsideClick(({ data, children, onClick, mobile}) => {
    return (
        <>
            {children ? (
                children
            ) : (
                <ul className={"navigation__sub" + (mobile ? " mobile_sub" : "")}>
                    {data.map((item) => (
                        <li
                            key={item.id}
                            className={`navigation__sub__item ${
                                item.isActive && "active__page"
                            } ${item.disabled ? "disabled" : ""}`}
                            onClick={(e) =>item.categories ? ()=>"" : onClick(e)}
                        >
                            {item.categories && item.categories.length ? (
                                <DropdownSub
                                    category={item}
                                    subCategories={item.categories}
                                    onClick={onClick}
                                    mobile={mobile}
                                />
                            ) : (
                                <Link to={item.disabled ? "" : item.link}>
                                    {item.name}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
});

export default Dropdown;
