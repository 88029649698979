import { useTranslation } from "react-i18next";
import React from "react";
import styles from "../../assets/styles/footer.module.scss";

import { contactMail, contactNumber, phoneNumber } from "../../constants/company";

const HomeFooter = () => {
    const { t } = useTranslation();

    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <div className={styles.contactUs}>
                    <p>{t("home.contactUs")}:</p>
                </div>
                <div className={styles.infoContainer}>
                    <a href={`whatsapp://send?phone=${contactNumber}&text=''`} className={styles["home__footer__number"]}>{contactNumber}</a>
                    <a href={`http:://${contactMail}`} className={styles["home__footer__mail"]}>
                        {contactMail}
                    </a>
                    <a href={`tel:+852 3678 6741`} className={styles["home__phone__number"]}>{phoneNumber}</a>
                </div>
            </div>
        </footer>
    );
};

export default HomeFooter;
