import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import { Translation } from "./constants/translation";

const resources = Translation;

i18n.use(initReactI18next).init({
    resources,
    lng: "english",
    interpolation: {
        escapeValue: false
    }
});