const ADDRESSES = [
  {
    title: "Prizesmart",
    id: 1,
    data: [
      {
        title: "Tsuen Wan Lik Sang",
        id: 1,
        address:
          "Shop 153, Lik Sang Plaza, 269 Castle Peak Rd Tsuen Wan, NT.",
        coordinates: {
          lat: "22.373289467135915",
          long: "114.11636013011443",
        },
      },
      {
        title: "Mei Foo",
        id: 2,
        address:
          "Shop 150-151B2, Podium Floor, 41 - 43 Broadway, Mei Foo Sun Chuen, KLN.",
        coordinates: {
          lat: "22.336015787271037",
          long: "114.13933469847609",
        },
      },
      {
        title: "Tsing Yi",
        id: 3,
        address:
          "Shop 1A, 1/F, Tsing Yi Garden, No. 7-19 Tsing Luk Street, Tsing Yi, N.T.",
        coordinates: {
          lat: "22.35413540796817",
          long: "114.1043160359347",
        },
      },
      {
        title: "Tsuen Wan Riviera Garden",
        id: 4,
        address:
          "Shop S2 & S12, Podium D, 1/F, Riviera Shopping Center, Riviera Gardens, Tsuen Wan, N.T.",
        coordinates: {
          lat: "22.36306185349662",
          long: "114.11302929730904",
        },
      },
      {
        title: "Shatin",
        id: 5,
        address:
          "Shop 32, 1/F The Podium Fu Fai Garden, 28 On Shing St Shatin, N.T.",
        coordinates: {
          lat: "22.38550519391605",
          long: "114.20445192429418",
        },
      },
      {
        title: "Ma On Shan",
        id: 6,
        address:
          "Shop 153, Lik Sang Plaza, 269 Castle Peak Rd Tsuen Wan, NT",
        coordinates: {
          lat: "22.4249928708326",
          long: "114.23325032429484",
        },
      },
      {
        title: "Tai Po",
        id: 7,
        address: "Shop 9, G/F Tung Fat Square, Tai Po, NT.",
        coordinates: {
          lat: "22.448463022940395",
          long: "114.16905065498236",
        },
      },
      {
        title: "Fanling",
        id: 8,
        address:
          "Shop H, G/F., Tai Cheung Building, 55-69A Wo Tai Street, Luen Wo Market, Fanling, N.T.",
        coordinates: {
          lat: "22.500351955062598",
          long: "114.14257866847586",
        },
      },
      {
        title: "Sai Kung",
        id: 9,
        address:
          "Shop 3, G/F, Sai Kung Building, 42-56 Fuk Man Road, Sai Kung, N.T.",
        coordinates: {
          lat: "22.38269039616912",
          long: "114.27260808196587",
        },
      },
      {
        title: "Tuen Mun Chi Lok",
        id: 10,
        address:
          "Shop 50-51, G/F, Come On Building, 387 Castle Peak Rd Tuen Mun, NT",
        coordinates: {
          lat: "22.389090987520902",
          long: "113.98012191265308",
        },
      },
      {
        title: "Tuen Mun Richland",
        id: 11,
        address:
          "Shop 1B1, G/F, Richland Garden, 138 Wu Chui Rd Tuen Mun, NT",
        coordinates: {
          lat: "22.37292638683293",
          long: "113.96430731551618",
        },
      },
      {
        title: "Yuen Long 1/F",
        id: 12,
        address:
          "Shop 2, G/F., Full Cheung House, No.93 Yeun Long On Ning Road, Yuen Long, N.T.",
        coordinates: {
          lat: "22.444262408215845",
          long: "114.02889155498221",
        },
      },
      {
        title: "Yuen Long 3/F",
        id: 13,
        address:
          "Shop 18, 3/F, Tung Yik Building, 8 Yu King Square Yuen Long, NT",
        coordinates: {
          lat: "22.444331821187212",
          long: "114.02892374148988",
        },
      },
      {
        title: "Yuen Long Fu Cheung",
        id: 14,
        address:
          "Shop 2, G/F., Full Cheung House, No.93 Yeun Long On Ning Road, Yuen Long, N.T.",
        coordinates: {
          lat: "22.44457424003753",
          long: "114.02664164149003",
        },
      },
      {
        title: "Tuen MunSan Hui",
        id: 15,
        address:
          "Shop 18B 1/F., Hing Fat Bldg., No. 49 Kai Man Path,  Tuen Mun, N.T.",
        coordinates: {
          lat: "22.397331697252266",
          long: "113.97527271265332",
        },
      },
      {
        title: "Tin shui Wai",
        id: 16,
        address:
          "Shop A73,A74,A75, G/F., Kingswood Richly Plaza, 1 Tin Wu Road, Tin Shui Wan, Yuen Long, N.T.",
        coordinates: {
          lat: "22.45176714046601",
          long: "114.00105599889504",
        },
      },
      {
        title: "Hung Hom",
        id: 17,
        address:
          "Shop D7 1/F, Planet Square, 1-15 Tak Man Street, Hung Hom, Kowloon.",
        coordinates: {
          lat: "22.306549841443214",
          long: "114.18748557866107",
        },
      },
      {
        title: "Laguna City",
        id: 18,
        address:
          "Shop 27, Laguna Arcade of the Comm. Deve G/F,\nBlock 32 to 38, 95 Cha Kwo Ling Road, Laguna City, Kowloon.",
        coordinates: {
          lat: "22.305577763404486",
          long: "114.22790864148745",
        },
      },
      {
        title: "North Poin",
        id: 19,
        address:
          "Shop 52, G/F, Block 10, City Garden, 233 Electric Road, Northpoint, HK",
        coordinates: {
          lat: "22.290997262117845",
          long: "114.19303565312825",
        },
      },
      {
        title: "Tseung Kwan O",
        id: 20,
        address:
          "Shop No.29 Ground Floor, Beverly Garden, 1 Tong Ming Street, Tseung Kwan O, Sai Kung, N.T.",
        coordinates: {
          lat: "22.311349500318787",
          long: "114.26211751080044",
        },
      },
      {
        title: "Tai Kok Tsui",
        id: 21,
        address:
          "Shop No.8, G/F, KING WANG HEIGHTS, 1-39 TIT SHU STREET ,TAI KOK TSUI, Kowloon.",
        coordinates: {
          lat: "22.319186786941156",
          long: "114.16340983963649",
        },
      },
      {
        title: "Queen's Road West",
        id: 22,
        address:
          "Ground Floor (Rear Portion), No. 425P Queen's Road West, Hong Kong.",
        coordinates: {
          lat: "22.2863201156008",
          long: "114.13690023963568",
        },
      },
      {
        title: "Shek Kip Mei",
        id: 23,
        address:
          "shop 208, G/F, Kam Yuck Bldg, 2 Wai Chi Lane, Kowloon",
        coordinates: {
          lat: "22.334788220668003",
          long: "114.16695848381609",
        },
      },
      {
        title: "Ma On Shan",
        id: 24,
        address:
          "Shop 78, 1/F The Podium Fu Fai Garden, 28 On Shing St Shatin, N.T.",
        coordinates: {
          lat: "22.424794520325367",
          long: "114.23331469731018",
        },
      },
      {
        title: "SIU SAI WAN",
        id: 25,
        address:
          "Shop 173, 1/F, COMM. CTR. FULLVIEW GARDEN, 18 SIU SAI WAN ROAD, HONG KONG.",
        coordinates: {
          lat: "22.263022702473137",
          long: "114.2517096936047",
        },
      },
    ],
  },
  {
    title: "Toko Bang Yau",
    id: 2,
    data: [
      {
        title: "",
        id: 1,
        address:
          "SHOP 101, 1/F, FULLVIEW GARDEN, 18 SIU SAI WAN ROAD, CHAI WAN, HONG KONG",
        coordinates: {
          lat: "22.2630425605688",
          long: "114.2517096936047",
        },
      },
      {
        title: "",
        id: 2,
        address:
          "SHOP C PART B, G/F, ON SHUN BUILDING, 17-19 SHUNG YAN STREET, KWUN TONG, KOWLOON",
        coordinates: {
          lat: "22.3161826858626",
          long: "114.2240867819646",
        },
      },
      {
        title: "",
        id: 3,
        address:
          "SHOP 10, G/F, YEUNG ON BUILDING, 50-54 SHAU KEI WAN ROAD, SAI WAN HO, HONG KONG",
        coordinates: {
          lat: "22.28320679305525",
          long: "114.22097272614342",
        },
      },
      {
        title: "",
        id: 4,
        address:
          "SHOP 117, LG1/F, SHEK YAM SHOPPING CENTRE, SHEK YAM ESTATE, 120 LEI MUK ROAD, KWAI CHUNG, NEW TERRITORIES",
        coordinates: {
          lat: "22.371711277195345",
          long: "114.13859148249945",
        },
      },
      {
        title: "",
        id: 5,
        address:
          "SHOP CF54, G/F, CHEUNG FAT PLAZA/CHEUNG FAT MARKET, CHEUNG FAT ESTATE, TSING YI, NEW TERRITORIES",
        coordinates: {
          lat: "22.362394747108844",
          long: "114.10336299730889",
        },
      },
    ],
  },
  {
    title: "Globent",
    id: 3,
    data: [
      {
        title: "GLOBENET DROID LIMITED",
        id: 1,
        address:
          "SHOP 56, G/F, CHUNGKING MANSIONS, 36-44 NATHAN ROAD, TSIM SHA TSUI, KOWLOON",
        coordinates: {
          lat: "22.29647597863686",
          long: "114.17283047984606",
        },
      },
      {
        title: "GLOBENET DROID LIMITED",
        id: 2,
        address:
          "FLAT 5G, 5/F, ALPHA HOUSE, 27-33 NATHAN ROAD, TSIM SHA TSUI, KOWLOON",
        coordinates: {
          lat: "22.296576552206695",
          long: "114.17198028196425",
        },
      },
    ],
  },
  {
    title: "H & L Saba Tranding",
    id: 4,
    data: [
      {
        title: "DE JESUS LEONARD SABA TRADING AS H&L",
        id: 1,
        address:
          "SHOP 7, G/F, LUCKIFAST BLDG, 1 STONE NULLAH LANE, WAN CHAI, HONG KONG",
        coordinates: {
          lat: "22.2763315350919",
          long: "114.17348756847144",
        },
      },
    ],
  },
  {
    title: "Prime Exchange",
    id: 5,
    data: [
      {
        title: "PRIME EXCHANGE LIMITED",
        id: 1,
        address:
          "UNIT 326, 3/F, HANKOW CENTRE, 5-15 HANKOW ROAD, TSIM SHA TSUI, KOWLOON",
        coordinates: {
          lat: "22.29608980651029",
          long: "114.17093925894888",
        },
      },
    ],
  },
];


export default ADDRESSES