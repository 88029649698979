import React, { useEffect, useRef} from "react";
import styles from "./Payments.module.scss";
import ContentBox from "../../common/ContentBox";
import { ViewRightLeft, ViewImage } from "../../View";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Text, TextWithIcon } from "../../typography";
import collectPaymentsBanner from "../../../assets/media/payments/collectPaymentsBanner.svg";
import marketplaceBanner from "../../../assets/media/payments/marketplaceBanner.svg";
import InputCard from "../../common/InputCard/InputCard";
import EmailInput from "../../common/EmailInput";

const Payments = ({ history }) => {
    const { t } = useTranslation();

    const payoutRef = useRef();
    const collectPaymentsRef = useRef();
    const marketplaceRef = useRef();
    

    const { state } = useLocation();
   
    useEffect(() => {
        setTimeout(
            () => {
                switch (state) {
                    case "payout":
                        setTimeout(()=>payoutRef.current.scrollIntoView({behavior:"smooth", block:"center"}),300)
                        break;
                    case "collectpayments":
                        setTimeout(()=>collectPaymentsRef.current.scrollIntoView({behavior:"smooth", block:"center"}),300)
                        break;
                    case "marketplace":
                        setTimeout(()=>marketplaceRef.current.scrollIntoView({behavior:"smooth",block:"center"}),300)
                        break;
                    default:
                        break;
                }
            },
            300
        );
    }, [state]);

    const hiddenData = [
        {
            subTitle: t("payments.hiddenSubTitle"),
            description: t("payments.hiddenDescription"),
        },
    ];

    const payoutTexts = t("payments.payout", { returnObjects: true });
    const marketplaceTexts = t("payments.marketplace", { returnObjects: true });

    return (
        <>
            <ViewRightLeft ref={collectPaymentsRef}>
                <ContentBox
                    title={t("payments.title")}
                    description={t("payments.description")}
                    data={hiddenData}
                />
                <ViewImage image={collectPaymentsBanner}></ViewImage>
            </ViewRightLeft>
            <div className={styles["smallTitle__container"]} ref={payoutRef}>
                <Text text="payout" size="xl" bold={true} color="blue">
                    payout
                </Text>
                <div className={styles["description"]}>
                    {payoutTexts.map((val, index) => {
                        const key = Object.keys(val)[0];
                        const reactKey = Math.random() + index;
                        if (key.includes("underline")) {
                            return (
                                <Text
                                    key={reactKey}
                                    text={val[key]}
                                    size="lg"
                                    bold={true}
                                    underline={true}
                                ></Text>
                            );
                        } else {
                            return (
                                <Text
                                    key={reactKey}
                                    text={val[key]}
                                    size="lg"
                                    bold={true}
                                ></Text>
                            );
                        }
                    })}
                </div>
            </div>
            <div className={styles["marketplace__container"]}>
                <Text
                    text="marketplace"
                    size="xl"
                    align="center"
                    color="blue"
                    bold={true}
                ></Text>
                <div>
                    <ViewRightLeft ref={marketplaceRef}>
                        <div className={styles["col"]}>
                            {marketplaceTexts["textsWithIcon"].map(
                                (val, index) => (
                                    <TextWithIcon
                                        key={Math.random() + index}
                                        textSettings={{
                                            text: val,
                                            size: "lg",
                                            bold: true,
                                        }}
                                    ></TextWithIcon>
                                )
                            )}
                            <div className={styles["row"]}>
                                <Text
                                    text={marketplaceTexts["textGreen"]}
                                    color="green"
                                    size="lg"
                                    bold={true}
                                ></Text>
                                <Text
                                    text={
                                        marketplaceTexts["textGreenBackground"]
                                    }
                                    background={"backgroundGreen"}
                                    size="lg"
                                    bold={true}
                                ></Text>
                                <Text
                                    text={marketplaceTexts["text"]}
                                    size="lg"
                                    bold="true"
                                ></Text>
                            </div>
                        </div>
                        <ViewImage
                            image={marketplaceBanner}
                            backgroundBox={true}
                        ></ViewImage>
                    </ViewRightLeft>
                    <InputCard
                        title="want to"
                        titleGreen="partner with us?"
                        type="blank"
                        subtitle="join us, and leave the rest to us"
                    >
                        <EmailInput buttonName={"join"}></EmailInput>
                    </InputCard>
                </div>
            </div>
        </>
    );
};

export default Payments;
