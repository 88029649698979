import React from "react";
import styles from "./TextWithIcon.module.scss";
import { string  } from "prop-types";
import Text  from "../text";

const TextSuccessIcon = ({ icon, textSettings }) => {
    return (
        <div className={styles["container"]}>
            <img className={styles["icon"]} src={icon} alt="Success Icon"></img>
            <Text {...textSettings}></Text>
        </div>
    );
};

TextSuccessIcon.propTypes = {
  textSettings : Text.propTypes,
  icon : string,
}

TextSuccessIcon.defaultProps = {
  icon : "../../images/success_icon.svg"
}

export default TextSuccessIcon;
