import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import "./SendTo.css";

import CountryInput from "../../common/CountryInput";
import InputCard from "../../common/InputCard/InputCard";

import PaymentField from "./paymentField";

const TEMP_COUNTRIES = [
    "Thailand",
    "Philippines",
    "India",
    "Sri Lanka",
    "Vietnam",
    "Indonesia",
    "Nepal",
    "China",
];

const SendTo = () => {
    
    const [isPageActive, setIsPageActive] = useState(true);
    const { t } = useTranslation();
    const [country, setCountry] = useState();
    return (
        <section>
            {!isPageActive ? (
                <PaymentField
                    countries={TEMP_COUNTRIES}
                    country={country}
                    goBack={setIsPageActive}
                ></PaymentField>
            ) : (
                <InputCard
                    type="country-box"
                    title="what's your transaction destination?"
                >
                    <CountryInput
                        countriesList={TEMP_COUNTRIES}
                        onSetCountry={setCountry}
                        placeholder={t("sendTo.searchPlc")}
                        buttonName={t("sendTo.searchBtn")}
                        goBack={setIsPageActive}
                    />
                </InputCard>
            )}
        </section>
    );
};

export default SendTo;
